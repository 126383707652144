import {ChangeDetectionStrategy, Component, EventEmitter, OnDestroy, OnInit, Output} from '@angular/core';
import {Store, select} from '@ngrx/store';
import {BehaviorSubject, Observable, Subscription} from 'rxjs';
import {debounceTime, filter, map, switchMap, take} from 'rxjs/operators';
import {ReportingTrackersParams} from 'src/app/core/model/reporting';
import {UpdateReportingTrackersParamsAction} from 'src/app/core/store/reporting/reporting.action';
import {selectReportingTrackersParams} from 'src/app/core/store/reporting/reporting.selector';
import {SetTeamSkedsAction} from 'src/app/core/store/scheduler/scheduler.action';
import {selectTeam} from 'src/app/core/store/teams/teams.selector';
import {TimezoneService} from 'src/app/services/timezone.service';
import {selectActiveUser} from '../../../../core/store/active-user/active-user.selector';
import {selectRouterUrl} from '../../../../core/store/router/router.selector';
import {selectTeamSkeds} from '../../../../core/store/scheduler/scheduler.selectors';

@Component({
  selector: 'oph-top-bar',
  templateUrl: './oph-top-bar.component.html',
  styleUrls: ['./oph-top-bar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OphTopBarComponent implements OnInit, OnDestroy {
  @Output() toggleMenu = new EventEmitter();

  subscriptions = new Subscription();

  public readonly url$ = this.store$.pipe(select(selectRouterUrl));
  public readonly activeUser$ = this.store$.select(selectActiveUser);
  userTeamName$: Observable<string>;
  reportingTrackersParams$: Observable<ReportingTrackersParams> = this.store$.select(selectReportingTrackersParams);
  scheduleSelected$: Observable<boolean> = this.store$.pipe(select(selectTeamSkeds));
  currentSkedScreen$: Observable<boolean>;

  search$ = new BehaviorSubject<{value: string; type: string}>({value: '', type: ''});

  constructor(
    private store$: Store,
    private timeZoneService: TimezoneService
  ) {}

  ngOnInit() {
    this.currentSkedScreen$ = this.observeUrl();
    this.userTeamName$ = this.observeUser();

    this.subscriptions.add(this.subscribeToSearch());
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  subscribeToSearch() {
    this.search$.pipe(debounceTime(300)).subscribe(search => {
      if (search.type === 'reportingTrackers') {
        this.updateReportingTrackersParams(search.value);
      }
    });
  }

  observeUrl(): Observable<boolean> {
    return this.url$.pipe(
      filter(url => !!url),
      map(url => url.startsWith('/current-sked'))
    );
  }

  observeUser(): Observable<string> {
    return this.activeUser$.pipe(
      filter(user => !!user?.teams?.length),
      switchMap(user => this.store$.pipe(select(selectTeam(user.teams[0])))),
      map(team => team?.name || 'Team name not found')
    );
  }

  onSelectChange($event: boolean) {
    this.store$.dispatch(new SetTeamSkedsAction($event));
  }

  getTimeZone(): string {
    return this.timeZoneService.getTimeZoneAbbr();
  }

  onReportingSearch(search: string) {
    this.search$.next({value: search, type: 'reportingTrackers'});
  }

  updateReportingTrackersParams(search: string) {
    this.reportingTrackersParams$.pipe(take(1)).subscribe(params => {
      const reportingTrackersParams = {...params, search};
      this.store$.dispatch(new UpdateReportingTrackersParamsAction({reportingTrackersParams}));
    });
  }
}
