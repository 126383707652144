import {TokensAction, TokensActionType} from './tokens.action';
import {initialTokensState, TokensState} from './tokens.state';

export function tokensReducer(state: TokensState = initialTokensState, action: TokensAction): TokensState {
  switch (action.type) {
    case TokensActionType.GET_ALL_SUCCESS:
      return {...state, tokenData: action.payload.tokenData};
    case TokensActionType.GET_SINGLE_SUCCESS:
      return {
        ...state,
        token: action.payload.token,
      };

    case TokensActionType.CREATE_NEW_SUCCESS:
    case TokensActionType.UPDATE_SUCCESS:
    case TokensActionType.DUPLICATE_SUCCESS:
      return {...state, token: action.payload.token};
    case TokensActionType.CLEAR:
      return {
        tokenData: null,
        token: null,
      };
    default:
      return state;
  }
}
