import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {Sked} from 'src/app/current-sked/shared/model/sked';
import {AutoSked} from 'src/app/schedule/shared/model/autosked';
import {AutoSkedParams} from 'src/app/schedule/shared/model/schedule-query-params';
import {environment} from 'src/environments/environment';
import {SkedParams} from '../model/sked-params';

@Injectable({
  providedIn: 'root',
})
export class ScheduleAPIService {
  constructor(private http: HttpClient) {}

  getAutoSkeds(params: AutoSkedParams): Observable<AutoSked[]> {
    return this.http.get<AutoSked[]>(`${environment.apiUrl}/autoskeds`, {params: {...params}});
  }

  getAutoSked(autoSked: AutoSked): Observable<AutoSked> {
    return this.http.get<AutoSked>(`${environment.apiUrl}/autoskeds/${autoSked._id}`);
  }

  createAutoSked(autoSked: AutoSked): Observable<AutoSked> {
    return this.http.post<AutoSked>(`${environment.apiUrl}/autoskeds`, autoSked);
  }

  updateAutoSked(autoSked: AutoSked): Observable<AutoSked> {
    return this.http.put<AutoSked>(`${environment.apiUrl}/autoskeds/${autoSked._id}`, autoSked);
  }

  deleteAutoSked(autoSked: AutoSked): Observable<AutoSked> {
    return this.http.delete<AutoSked>(`${environment.apiUrl}/autoskeds/${autoSked._id}`);
  }

  getSkeds(params: SkedParams): Observable<Sked[]> {
    return this.http.get<Sked[]>(`${environment.apiUrl}/autoskeds/calendar`, {params: {...params}});
  }
}
