import {inject} from '@angular/core';
import {Router} from '@angular/router';
import {Store} from '@ngrx/store';
import {map, take} from 'rxjs/operators';
import {selectActiveUserRole} from '../store/active-user/active-user.selector';

export const adminGuard = () => {
  const store = inject(Store);
  const router = inject(Router);

  return store.select(selectActiveUserRole).pipe(
    take(1),
    map(role => {
      const isAdmin = role.toLowerCase() === 'admin';
      if (!isAdmin) {
        router.navigate(['/current-sked']);
        return false;
      }
      return true;
    })
  );
};
