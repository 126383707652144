import {createSelector} from '@ngrx/store';
import {AppState} from '../app-state';

export const selectCurrentSkedState = (state: AppState) => state.currentSked;

export const selectSelectedSkedType = createSelector(selectCurrentSkedState, state => state.selectedSkedType);

export const selectCurrentSkedSkeds = createSelector(
  selectSelectedSkedType,
  selectCurrentSkedState,
  (skedType, state) => {
    // User and team are sent as an object. All is an array. The component expects an array so user and team are sent as an array.
    if (skedType.type === 'user') {
      return state.userSked ? [state.userSked] : [];
    }
    if (skedType.type === 'team') {
      return state.teamSked ? [state.teamSked] : [];
    }
    if (skedType.type === 'all') {
      return state.allSkeds ? state.allSkeds : [];
    }
  }
);

export const selectCurrentSkedDetailTask = createSelector(selectCurrentSkedState, state => state.task);

export const selectCurrentSkedActiveTab = createSelector(selectCurrentSkedState, state => state.activeTab);

export const selectUpdatedTask = createSelector(selectCurrentSkedState, state => state.updatedTask);

export const selectCurrentSkedHeaderLoading = createSelector(selectCurrentSkedState, state => state.headerLoading);

export const selectCurrentSkedValueChosenMap = createSelector(
  selectCurrentSkedState,
  state => state.metricUpdateValueChosenMap
);
