@if (
  {
    reportingTrackers: reportingTrackers$ | async,
    reportingTrackersParams: reportingTrackersParams$ | async,
    projectsMap: projectsMap$ | async,
    projects: projects$ | async,
    isCurrentWeek: isCurrentWeek$ | async
  };
  as state
) {
  @if (!state.projectsMap || !state.reportingTrackersParams) {
    <div class="loading-container">
      <oph-loading></oph-loading>
    </div>
  } @else {
    <!-- -------------------------------------PAGE HEADER------------------------------------- -->
    <header>
      <project-selector
        [selectedProject]="state.projectsMap?.[state.reportingTrackersParams?.projectId]"
        [hideActionButton]="true"
      >
        <ng-container class="project-selector">
          @for (project of state.projects; track $index) {
            <project-selector-menu-project-button
              [project]="project"
              [isSelected]="project?.label === state.selectedProject?.label"
              (buttonClick)="onSelectProject(project)"
            ></project-selector-menu-project-button>
          }
        </ng-container>
      </project-selector>

      @if (state.projectsMap?.[state.reportingTrackersParams?.projectId]) {
        <oph-new-button
          [buttonStyle]="{padding: '0 8px 0 4px'}"
          (buttonClick)="onAddTracker(state.reportingTrackersParams?.projectId)"
          >New Tracker</oph-new-button
        >
      }
    </header>

    <!-- -------------------------------------MAIN CONTENT------------------------------------ -->
    <div class="main-content">
      <!-- -------------------------------------HEADER------------------------------------ -->
      <div class="main-content-header">
        <div class="title-container">Weekly</div>
        <div class="data-container">
          @for (day of weekArray; track $index) {
            <div class="data-item">{{ day.display }}</div>
          }
        </div>
        <div class="button-container">
          <button class="week-button" (click)="onWeek(false)">
            @if (lastLoading$ | async) {
              <div class="oph-orange-spinner">
                <mat-spinner diameter="22"></mat-spinner>
              </div>
            } @else {
              <mat-icon svgIcon="chevron-left"></mat-icon>
            }
          </button>
          <button
            class="week-button"
            (click)="onWeek(true)"
            [disabled]="state.isCurrentWeek"
            [class.disabled]="state.isCurrentWeek"
          >
            @if (nextLoading$ | async) {
              <div class="oph-orange-spinner">
                <mat-spinner diameter="22"></mat-spinner>
              </div>
            } @else {
              <mat-icon svgIcon="chevron-right"></mat-icon>
            }
          </button>
        </div>
      </div>

      @if (!state.reportingTrackers) {
        <div class="loading-container">
          <oph-loading></oph-loading>
        </div>
      } @else {
        <!-- -------------------------------------TRACKERS------------------------------------ -->
        @if (!state.reportingTrackers?.trackers?.length) {
          <div class="no-report">No trackers were found</div>
        } @else {
          @for (tracker of state.reportingTrackers?.trackers; track $index) {
            <div class="tracker-container" [class.empty-tracker]="!tracker.metrics?.length">
              <!-- -----HEADER----- -->
              <div class="tracker-container-header">
                <div class="title-container tracker-title">
                  <span>{{ tracker.name }}</span>
                </div>
                <div class="project-list">
                  @for (projectId of tracker.projectIds; track $index) {
                    <oph-chip
                      [color]="state.projectsMap[projectId]?.color"
                      [text]="state.projectsMap[projectId]?.label || 'Untitled'"
                      maxWidth="250"
                    ></oph-chip>
                  }
                </div>
              </div>

              <!-- -----METRICS----- -->
              <div class="metrics-container">
                @if (!tracker.metrics?.length) {
                  <div class="no-report">There is no report for this time range</div>
                } @else {
                  @for (metric of tracker.metrics; track $index; let metricIndex = $index) {
                    <div class="metric-row" [class.dark-background]="!(metricIndex | isEvenNumber)">
                      <div class="title-container metric-title">
                        <span>{{ metric.name }}</span>
                      </div>
                      <div class="data-container">
                        @for (day of weekArray; track $index) {
                          <div class="data-item">
                            <span>{{ metric.logs?.[day.value]?.at(-1)?.value | trackerMetricValue: metric.type }}</span>
                          </div>
                        }
                      </div>
                    </div>
                  }
                }
              </div>
            </div>
          }
        }
      }
    </div>
  }
}
