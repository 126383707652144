import {HttpErrorResponse} from '@angular/common/http';
import {Action as ActionModel} from '@ngrx/store';
import {ReportingTrackers, ReportingTrackersParams} from '../../model/reporting';
import {ReportingObjectEntry} from './../../../reporting/objects/shared/model/reporting-object-entry';
import {ReportingObjectsParams} from './../../../reporting/objects/shared/model/reporting-objects-params';

export enum ReportingActionType {
  GET_TRACKERS_REPORT = '[Reporting] Get Trackers Report',
  GET_TRACKERS_REPORT_SUCCESS = '[Reporting] Get Trackers Report :: Success',

  UPDATE_REPORTING_TRACKERS_PARAMS = '[Reporting] Update Reporting Trackers Params',

  GET_OBJECTS_REPORT = '[Reporting] Get Objects Report',
  GET_OBJECTS_REPORT_SUCCESS = '[Reporting] Get Objects Report :: Success',

  GET_OBJECT_PARENT_REPORT = '[Reporting] Get Object Parent Report',
  GET_OBJECT_PARENT_REPORT_SUCCESS = '[Reporting] Get Object Parent Report :: Success',

  GET_OBJECT_INSTANCE_REPORT = '[Reporting] Get Object Instance Report',
  GET_OBJECT_INSTANCE_REPORT_SUCCESS = '[Reporting] Get Object Instance Report :: Success',

  GET_OBJECT_ATTRIBUTE_REPORT = '[Reporting] Get Object Attribute Report',
  GET_OBJECT_ATTRIBUTE_REPORT_SUCCESS = '[Reporting] Get Object Attribute Report :: Success',

  CLEAR = '[Reporting] Clear',
}

export class GetTrackersReportAction implements ActionModel {
  public readonly type = ReportingActionType.GET_TRACKERS_REPORT;

  public constructor(
    public payload: {
      params: ReportingTrackersParams;
      onSuccess?: () => void;
      onFailure?: (error: HttpErrorResponse) => void;
    }
  ) {}
}

export class GetTrackersReportSuccessAction implements ActionModel {
  public readonly type = ReportingActionType.GET_TRACKERS_REPORT_SUCCESS;

  public constructor(public payload: {trackersReport: ReportingTrackers; params: ReportingTrackersParams}) {}
}

export class UpdateReportingTrackersParamsAction implements ActionModel {
  public readonly type = ReportingActionType.UPDATE_REPORTING_TRACKERS_PARAMS;

  public constructor(public payload: {reportingTrackersParams: ReportingTrackersParams}) {}
}

export class GetObjectsReportAction implements ActionModel {
  public readonly type = ReportingActionType.GET_OBJECTS_REPORT;

  public constructor(
    public payload: {
      params: ReportingObjectsParams;
      onSuccess?: () => void;
      onFailure?: (error: Error) => void;
    }
  ) {}
}

export class GetObjectsReportSuccessAction implements ActionModel {
  public readonly type = ReportingActionType.GET_OBJECTS_REPORT_SUCCESS;

  public constructor(public payload: {reportingObjectEntries: ReportingObjectEntry[]}) {}
}

export class GetObjectParentReportAction implements ActionModel {
  public readonly type = ReportingActionType.GET_OBJECT_PARENT_REPORT;

  public constructor(
    public payload: {
      parentId: string;
      params: ReportingObjectsParams;
      onSuccess?: () => void;
      onFailure?: (error: Error) => void;
    }
  ) {}
}

export class GetObjectParentReportSuccessAction implements ActionModel {
  public readonly type = ReportingActionType.GET_OBJECT_PARENT_REPORT_SUCCESS;

  public constructor(public payload: {reportingObjectEntries: ReportingObjectEntry[]}) {}
}

export class GetObjectInstanceReportAction implements ActionModel {
  public readonly type = ReportingActionType.GET_OBJECT_INSTANCE_REPORT;

  public constructor(
    public payload: {
      instanceId: string;
      params: ReportingObjectsParams;
      onSuccess?: () => void;
      onFailure?: (error: Error) => void;
    }
  ) {}
}

export class GetObjectInstanceReportSuccessAction implements ActionModel {
  public readonly type = ReportingActionType.GET_OBJECT_INSTANCE_REPORT_SUCCESS;

  public constructor(public payload: {reportingObjectEntries: ReportingObjectEntry[]}) {}
}

export class GetObjectAttributeReportAction implements ActionModel {
  public readonly type = ReportingActionType.GET_OBJECT_ATTRIBUTE_REPORT;

  public constructor(
    public payload: {
      instanceId: string;
      attributeId: string;
      params: ReportingObjectsParams;
      onSuccess?: () => void;
      onFailure?: (error: Error) => void;
    }
  ) {}
}

export class GetObjectAttributeReportSuccessAction implements ActionModel {
  public readonly type = ReportingActionType.GET_OBJECT_ATTRIBUTE_REPORT_SUCCESS;

  public constructor(public payload: {reportingObjectEntries: ReportingObjectEntry[]}) {}
}

export class ClearReportingAction implements ActionModel {
  public readonly type = ReportingActionType.CLEAR;
}

export type ReportingAction =
  | GetTrackersReportAction
  | GetTrackersReportSuccessAction
  | UpdateReportingTrackersParamsAction
  | GetObjectsReportAction
  | GetObjectsReportSuccessAction
  | GetObjectParentReportAction
  | GetObjectParentReportSuccessAction
  | GetObjectInstanceReportAction
  | GetObjectInstanceReportSuccessAction
  | GetObjectAttributeReportAction
  | GetObjectAttributeReportSuccessAction
  | ClearReportingAction;
