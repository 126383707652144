import {ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, Output, SimpleChanges} from '@angular/core';
import {select} from '@ngrx/store';
import {Store} from '@ngrx/store';
import {BehaviorSubject, map, Observable} from 'rxjs';
import {MENU_ITEMS, MenuItem} from 'src/app/app.constants';
import {User} from 'src/app/core/model/user';
import {selectActiveUserRole} from 'src/app/core/store/active-user/active-user.selector';

@Component({
  selector: 'mobile-navbar',
  templateUrl: './mobile-navbar.component.html',
  styleUrls: ['./mobile-navbar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MobileNavbarComponent implements OnChanges {
  @Input() user: User;
  @Input() routerUrl: string;

  @Output() mobileNavItemClick = new EventEmitter();
  @Output() logout = new EventEmitter();

  userRole$: Observable<string> = this.store$.pipe(select(selectActiveUserRole)).pipe(map(role => role?.toLowerCase()));
  filteredMenuItems$: Observable<MenuItem[]>;

  selectedIndex$ = new BehaviorSubject<number>(null);

  constructor(private store$: Store) {
    this.filteredMenuItems$ = this.observeUserRole();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.routerUrl && this.routerUrl) {
      const index = MENU_ITEMS.findIndex(item => this.routerUrl.startsWith(item.url));
      this.selectedIndex$.next(index);
    }
  }

  observeUserRole(): Observable<MenuItem[]> {
    return this.userRole$.pipe(
      map(role => {
        if (role === 'admin') {
          return MENU_ITEMS;
        }
        return MENU_ITEMS.filter(item => !item.adminOnly);
      })
    );
  }
}
