import {CurrentSkedTabOptions} from 'src/app/current-sked/task-list/shared/current-sked-tab-options';
import {CurrentSkedAction, CurrentSkedActionType} from './current-sked.action';
import {CurrentSkedState, initialCurrentSkedState} from './current-sked.state';
import {CurrentSkedTask} from '../../model/task';

export function currentSkedReducer(
  state: CurrentSkedState = initialCurrentSkedState,
  action: CurrentSkedAction
): CurrentSkedState {
  switch (action.type) {
    case CurrentSkedActionType.GET_SUCCESS: {
      if (action.payload.skedType.type === 'user') {
        // user sked
        return {...state, userSked: action.payload.sked};
      } else {
        // team sked
        return {...state, teamSked: action.payload.sked};
      }
    }
    case CurrentSkedActionType.GET_ALL_SKEDS_SUCCESS:
      return {...state, allSkeds: action.payload.skeds};
    case CurrentSkedActionType.GET_TASK_SUCCESS:
    case CurrentSkedActionType.CHANGE_TASK_STATUS_SUCCESS:
    case CurrentSkedActionType.CHANGE_SUBTASK_STATUS_SUCCESS:
    case CurrentSkedActionType.UPDATE_SUBTASK_METRIC_SUCCESS:
      return {...state, task: action.payload.task};
    case CurrentSkedActionType.UPDATE_ALL_SKEDS:
      return {...state, allSkeds: action.payload.allSkeds};
    case CurrentSkedActionType.UPDATE_ACTIVE_TAB:
      return {...state, activeTab: action.payload.activeTab};
    case CurrentSkedActionType.UPDATE_SELECTED_SKED_TYPE:
      return {...state, selectedSkedType: action.payload.selectedSkedType};
    case CurrentSkedActionType.UPDATE_HEADER_LOADING:
      return {...state, headerLoading: action.payload.headerLoading};
    case CurrentSkedActionType.UPDATE_VALUE_CHOSEN:
      return {
        ...state,
        metricUpdateValueChosenMap: {
          ...state.metricUpdateValueChosenMap,
          [action.payload.id]: true,
        },
      };
    case CurrentSkedActionType.CLEAR_VALUE_CHOSEN:
      return {...state, metricUpdateValueChosenMap: {}};
    case CurrentSkedActionType.CLEAR_TASK:
      return {...state, task: null};
    case CurrentSkedActionType.CLEAR: {
      return {
        userSked: null,
        teamSked: null,
        allSkeds: [], // new array
        headerLoading: false,
        task: null,
        selectedSkedType: {id: '', type: 'user'}, // new object
        activeTab: CurrentSkedTabOptions.Sked,
        updatedTask: {} as CurrentSkedTask, // new object
        metricUpdateValueChosenMap: {}, // new object
      };
    }
    default:
      return state;
  }
}
