import {Action as ActionModel} from '@ngrx/store';
import {Team} from '../../../teams/shared/model/team';

export enum TeamsActionType {
  GET = '[Teams] Get',
  GET_SUCCESS = '[Teams] Get :: Success',

  CREATE = '[Teams] Create',
  CREATE_SUCCESS = '[Teams] Create :: Success',

  UPDATE = '[Teams] Update',
  UPDATE_SUCCESS = '[Teams] Update :: Success',

  DELETE = '[Teams] Delete',
  DELETE_SUCCESS = '[Teams] Delete :: Success',

  CLEAR = '[Teams] Clear',
}

export class GetTeamsAction implements ActionModel {
  public readonly type = TeamsActionType.GET;

  public constructor(
    public payload: {
      onSuccess?: (teams: string[]) => void;
      onFailure?: (error: Error) => void;
    }
  ) {}
}

export class GetTeamsSuccessAction implements ActionModel {
  public readonly type = TeamsActionType.GET_SUCCESS;

  public constructor(public payload: {teams: Team[]}) {}
}

export class CreateTeamAction implements ActionModel {
  public readonly type = TeamsActionType.CREATE;

  public constructor(
    public payload: {
      team: Team;
      onSuccess?: (team: Team) => void;
      onFailure?: (error: Error) => void;
    }
  ) {}
}

export class CreateTeamSuccessAction implements ActionModel {
  public readonly type = TeamsActionType.CREATE_SUCCESS;

  public constructor(public payload: {team: Team}) {}
}

export class UpdateTeamAction implements ActionModel {
  public readonly type = TeamsActionType.UPDATE;

  public constructor(
    public payload: {
      team: Team;
      onSuccess?: (team: Team) => void;
      onFailure?: (error: Error) => void;
    }
  ) {}
}

export class UpdateTeamSuccessAction implements ActionModel {
  public readonly type = TeamsActionType.UPDATE_SUCCESS;

  public constructor(public payload: {team: Team}) {}
}

export class DeleteTeamAction implements ActionModel {
  public readonly type = TeamsActionType.DELETE;

  public constructor(
    public payload: {
      id: string;
      onSuccess?: () => void;
      onFailure?: (error: Error) => void;
    }
  ) {}
}

export class DeleteTeamSuccessAction implements ActionModel {
  public readonly type = TeamsActionType.DELETE_SUCCESS;
}

export class ClearTeamsAction implements ActionModel {
  public readonly type = TeamsActionType.CLEAR;
}

export type TeamsAction =
  | GetTeamsAction
  | GetTeamsSuccessAction
  | CreateTeamAction
  | CreateTeamSuccessAction
  | UpdateTeamAction
  | UpdateTeamSuccessAction
  | DeleteTeamAction
  | DeleteTeamSuccessAction
  | ClearTeamsAction;
