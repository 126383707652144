import {Project} from 'src/app/core/model/project';

export function getStepId(project: Project): string {
  const activeStep = project.steps.find(step => step.status === 'active');
  if (activeStep) return activeStep._id;

  const inactiveStep = project.steps.find(step => step.status === 'inactive');
  if (inactiveStep) return inactiveStep._id;

  const completeStep = project.steps.find(step => step.status === 'complete');
  return completeStep?._id || '';
}
