import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output} from '@angular/core';
import {MatIconModule} from '@angular/material/icon';
import {OphChipButtonComponent} from 'src/app/shared/design/oph-chip-button/oph-chip-button.component';

@Component({
  selector: 'projects-sked-selector',
  standalone: true,
  imports: [MatIconModule, OphChipButtonComponent],
  templateUrl: './projects-sked-selector.component.html',
  styleUrl: './projects-sked-selector.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProjectsSkedSelectorComponent {
  @Input() skeds: string[];
  @Input() loading: boolean;

  @Output() addSked = new EventEmitter();
}
