import {AsyncPipe, TitleCasePipe} from '@angular/common';
import {ChangeDetectionStrategy, Component, HostBinding, OnDestroy, OnInit} from '@angular/core';
import {MatIconModule} from '@angular/material/icon';
import {MatMenuModule} from '@angular/material/menu';
import {Router} from '@angular/router';
import {select, Store} from '@ngrx/store';
import {
  BehaviorSubject,
  debounceTime,
  distinctUntilChanged,
  filter,
  map,
  Observable,
  Subscription,
  take,
  tap,
} from 'rxjs';
import {ListSearchParams} from 'src/app/core/model/list-search-params';
import {TableTracker, Tracker, TrackerData} from 'src/app/core/model/tracker';
import {TitleService} from 'src/app/core/page/title.service';
import {CreateNewTrackerAction, GetAllTrackersAction} from 'src/app/core/store/trackers/trackers.action';
import {selectTrackerData, selectTrackersListParams} from 'src/app/core/store/trackers/trackers.selector';
import {MessageService} from 'src/app/services/message.service';
import {CreateFirstModule} from 'src/app/shared/design/create-first/create-first.module';
import {OphLoadingModule} from 'src/app/shared/design/oph-loading/oph-loading.module';
import {OphPaginatorComponent} from 'src/app/shared/design/oph-paginator/oph-paginator.component';
import {OphTableColumn, OphTableComponent} from 'src/app/shared/design/oph-table/oph-table.component';
import {ListViewsModule} from 'src/app/shared/list-views/list-views.module';
import {TrackerSortNamePipe} from '../shared/tracker-sort-name.pipe';
import {TrackersListGridItemComponent} from './grid-item/trackers-list-grid-item.component';

@Component({
  selector: 'trackers-list',
  standalone: true,
  imports: [
    OphTableComponent,
    AsyncPipe,
    ListViewsModule,
    OphPaginatorComponent,
    TrackersListGridItemComponent,
    MatMenuModule,
    MatIconModule,
    TitleCasePipe,
    TrackerSortNamePipe,
    CreateFirstModule,
    OphLoadingModule,
  ],
  templateUrl: './trackers-list.component.html',
  styleUrl: './trackers-list.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TrackersListComponent implements OnInit, OnDestroy {
  @HostBinding('class') hostClasses = 'oph-feature-layout oph-feature-padding';

  subscriptions = new Subscription();

  params$: Observable<ListSearchParams>;
  trackerData$: Observable<TrackerData>;
  formattedTrackers$: Observable<TableTracker[]>;

  loading$ = new BehaviorSubject<boolean>(true);
  loadingNewTracker$ = new BehaviorSubject<boolean>(false);

  loadingParams: boolean = true;
  initiallyLoaded = false;

  columns: OphTableColumn[] = [
    {name: 'name', sortName: 'name', bold: true, width: '36%'},
    {name: 'updated on', type: 'date', sortName: 'lastUpdatedAt', width: '20%'},
    {name: 'updated by', sortName: 'lastUpdatedBy', width: '18%'},
    {name: 'metrics', sortName: 'metrics', width: '12%'},
    {name: 'projects', type: 'projects', width: '14%'},
    // {name: '', type: 'menu', width: '8%'},
  ];

  constructor(
    private store$: Store,
    private router: Router,
    private messageService: MessageService,
    private titleService: TitleService
  ) {}

  ngOnInit(): void {
    this.titleService.setPageTitle('Trackers');
    this.params$ = this.store$.pipe(select(selectTrackersListParams));
    this.trackerData$ = this.store$.pipe(select(selectTrackerData));
    this.formattedTrackers$ = this.observeTrackers();

    this.subscriptions.add(this.subscribeToParams());
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  subscribeToParams(): Subscription {
    if (!this.initiallyLoaded) {
      this.loading$.next(true);
    }
    return this.params$
      .pipe(
        distinctUntilChanged(),
        // This is to prevent the create-first component from showing when there are no trackers
        tap(() => (this.loadingParams = true)),
        debounceTime(300)
      )
      .subscribe(params => {
        const formattedParams: ListSearchParams = {};
        Object.keys(params).forEach(key => {
          if (params[key]) {
            formattedParams[key] = params[key];
          }
        });
        formattedParams.pageSize = 50;
        formattedParams.sortOrder = 'asc';

        this.store$.dispatch(
          new GetAllTrackersAction({
            params: formattedParams,
            onSuccess: () => this.getAllTrackersSuccess(),
            onFailure: err => this.getAllTrackersFailure(err),
          })
        );
      });
  }

  getAllTrackersSuccess() {
    this.loading$.next(false);
    this.loadingParams = false;
  }

  getAllTrackersFailure(err: Error) {
    this.loading$.next(false);
    this.messageService.add(err.message || 'There was problem getting Trackers.');
  }

  observeTrackers(): Observable<TableTracker[]> {
    return this.trackerData$.pipe(
      filter(t => !!t),
      map(trackerData => {
        return trackerData.trackers.map(tracker => {
          return {
            name: tracker.name,
            'updated on': tracker.lastUpdatedAt,
            'updated by': tracker.lastUpdatedBy,
            metrics: tracker.metricCount || 0,
            projects: tracker.projects?.filter(p => p.label !== 'Private Project'),
          };
        });
      })
    );
  }

  onTracker(index: number) {
    this.trackerData$.pipe(take(1)).subscribe(data => {
      const tracker = data.trackers[index];
      if (tracker) {
        this.router.navigate(['trackers', tracker._id, {returnTo: this.router.url}]);
      }
    });
  }

  onNewTracker() {
    this.loadingNewTracker$.next(true);
    this.store$.dispatch(
      new CreateNewTrackerAction({
        onSuccess: tracker => this.onCreateTrackerSuccess(tracker),
        onFailure: err => this.onCreateTrackerFailure(err),
      })
    );
  }

  onCreateTrackerSuccess(tracker: Tracker) {
    if (tracker?._id) {
      this.router.navigate(['trackers', tracker._id, {returnTo: this.router.url}]);
    }
    this.loadingNewTracker$.next(false);
  }

  onCreateTrackerFailure(err: Error) {
    this.messageService.add(err.message || 'There was a problem creating the tracker');
  }

  onSort(sortField: string) {
    this.router.navigate([], {queryParams: {sortField}, queryParamsHandling: 'merge'});
  }
}
