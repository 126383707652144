import {RewardCoin} from '../../model/reward';
import {RewardsAction, RewardsActionType} from './rewards.action';
import {initialRewardsState, RewardsState} from './rewards.state';

export function rewardsReducer(state: RewardsState = initialRewardsState, action: RewardsAction): RewardsState {
  switch (action.type) {
    case RewardsActionType.GET_MANAGE_SUCCESS:
      return {...state, manageRewards: action.payload.manageRewards};
    case RewardsActionType.GET_MY_REWARDS_SUCCESS:
      return {...state, myRewards: action.payload.myRewards};
    case RewardsActionType.GET_USER_HISTORY_SUCCESS:
      return {
        ...state,
        userHistory: action.payload.userHistory.assignments,
        historyPagination: action.payload.userHistory.pagination,
      };
    case RewardsActionType.GET_ADMIN_HISTORY_SUCCESS:
      return {
        ...state,
        adminHistory: action.payload.adminHistory.rewards,
        historyPagination: action.payload.adminHistory.pagination,
      };
    case RewardsActionType.GET_SINGLE_REWARD_SUCCESS:
      return {...state, singleReward: action.payload.reward};
    case RewardsActionType.GET_COIN_TYPES_SUCCESS: {
      const coinTypesMap = action.payload.coinTypes.reduce(
        (coinTypesMap: Record<string, RewardCoin>, obj: RewardCoin) => ((coinTypesMap[obj._id] = obj), coinTypesMap),
        {}
      );
      return {...state, coinTypes: action.payload.coinTypes, coinTypesMap};
    }
    case RewardsActionType.UPDATE_MANAGE_REWARDS_COIN_EXPANDED_MAP: {
      const manageRewardsCoinExpandedMap = state.manageRewardsCoinExpandedMap;
      manageRewardsCoinExpandedMap[action.payload.name] = action.payload.open;
      return {...state, manageRewardsCoinExpandedMap};
    }
    case RewardsActionType.UPDATE_MANAGE_REWARDS_REWARD_EXPANDED_MAP: {
      const manageRewardsRewardExpandedMap = state.manageRewardsCoinExpandedMap;
      manageRewardsRewardExpandedMap[action.payload.key] = action.payload.open;
      return {...state, manageRewardsRewardExpandedMap};
    }
    case RewardsActionType.UPDATE_MY_REWARDS_EXPANDED_MAP: {
      const myRewardsExpandedMap = state.myRewardsExpandedMap;
      myRewardsExpandedMap[action.payload.key] = action.payload.open;
      return {...state, myRewardsExpandedMap};
    }
    case RewardsActionType.UPDATE_REWARD_HISTORY_EXPANDED_MAP: {
      const rewardHistoryExpandedMap = state.rewardHistoryExpandedMap;
      rewardHistoryExpandedMap[action.payload.key] = action.payload.open;
      return {...state, rewardHistoryExpandedMap};
    }
    case RewardsActionType.UPDATE_HEADER_LOADING:
      return {...state, headerLoading: action.payload.headerLoading};
    case RewardsActionType.CLEAR:
      return {
        manageRewards: null,
        myRewards: null,
        userHistory: null,
        adminHistory: null,
        historyPagination: null,
        singleReward: null,
        coinTypes: [],
        coinTypesMap: {},
        manageRewardsCoinExpandedMap: {},
        manageRewardsRewardExpandedMap: {},
        myRewardsExpandedMap: {},
        rewardHistoryExpandedMap: {},
        headerLoading: false,
      };
    default:
      return state;
  }
}
