import {ScheduleAction, ScheduleActionType} from './schedule.action';
import {ScheduleState, initialScheduleState} from './schedule.state';

export function scheduleReducer(state: ScheduleState = initialScheduleState, action: ScheduleAction): ScheduleState {
  switch (action.type) {
    case ScheduleActionType.UPDATE_QUERY_PARAMS:
      return {...state, queryParams: action.payload.params};
    case ScheduleActionType.GET_AUTOSKEDS_SUCCESS:
      return {...state, autoSkeds: action.payload.autoSkeds};
    case ScheduleActionType.GET_SKEDS_SUCCESS:
      return {...state, skeds: action.payload.skeds};
    case ScheduleActionType.CLEAR:
      return {
        queryParams: null,
        autoSkeds: [],
        skeds: [],
        currentAutoSked: null,
      };
    default:
      return state;
  }
}
