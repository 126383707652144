import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {environment} from '../../../environments/environment';
import {Project, ProjectView, ProjectViewRoutineDetail, ProjectViewRoutineDetailParams} from '../model/project';
import {ProjectTask, TaskTemplate} from '../model/task';

@Injectable({
  providedIn: 'root',
})
export class ProjectsApiService {
  public url = `${environment.apiUrl}/projects`;
  public tasksUrl = `${environment.apiUrl}/tasks`;

  constructor(private http: HttpClient) {}

  get(): Observable<Project[]> {
    return this.http.get<Project[]>(`${this.url}`);
  }

  getSingle(id: string): Observable<Project> {
    return this.http.get<Project>(`${this.url}/${id}`);
  }

  create(project: Partial<Project>): Observable<Project> {
    return this.http.post<Project>(`${this.url}`, project);
  }

  patch(project: Project): Observable<Project> {
    return this.http.patch<Project>(`${this.url}/${project._id}/scheduleProject`, project);
  }

  put(project: Project): Observable<Project> {
    return this.http.put<Project>(`${this.url}/${project._id}`, project);
  }

  duplicate(id: string): Observable<Project> {
    return this.http.post<Project>(`${this.url}/${id}`, null);
  }

  delete(id: string): Observable<Object> {
    return this.http.delete(`${this.url}/${id}`);
  }

  addOrMoveProjectTask(
    projectId: string,
    stepId: string,
    taskIndex: number,
    task: ProjectTask,
    add: boolean
  ): Observable<Project> {
    const urlSuffix = add ? 'addTask' : 'moveTask';
    const payload = {
      stepId,
      task,
      index: taskIndex,
    };
    return this.http.patch<Project>(`${this.url}/${projectId}/${urlSuffix}`, payload);
  }

  updateProjectTask(projectId: string, stepId: string, task: ProjectTask): Observable<Project> {
    return this.http.patch<Project>(`${this.url}/${projectId}/updateTask`, {stepId, task});
  }

  deleteProjectTask(projectId: string, taskInstanceId: string): Observable<Project> {
    return this.http.patch<Project>(`${this.url}/${projectId}/removeTask/${taskInstanceId}`, {});
  }

  getProjectView(projectId: string, params: Record<string, string>): Observable<ProjectView> {
    return this.http.get<ProjectView>(`${this.url}/viewer/${projectId}`, {params: {...params}});
  }

  getRoutineDetail(id: string, params: ProjectViewRoutineDetailParams): Observable<ProjectViewRoutineDetail> {
    return this.http.get<ProjectViewRoutineDetail>(`${this.url}/viewer/routines/${id}`, {params: {...params}});
  }

  getTaskTemplates(search?: string): Observable<TaskTemplate[]> {
    const params = search ? {label: search} : {};
    return this.http.get<TaskTemplate[]>(`${this.tasksUrl}/templates`, {params});
  }

  createTaskTemplate(task: TaskTemplate): Observable<ProjectTask> {
    return this.http.post<ProjectTask>(`${this.tasksUrl}/templates`, task);
  }

  updateTaskTemplate(task: TaskTemplate): Observable<ProjectTask> {
    return this.http.put<ProjectTask>(`${this.tasksUrl}/templates/${task._id}`, task);
  }

  deleteTaskTemplate(id: string) {
    return this.http.delete(`${this.tasksUrl}/templates/${id}`);
  }

  createStep(project: Project): Observable<Project> {
    // this will change
    return this.http.put<Project>(`${this.url}/${project._id}`, project);
  }

  updateStep(project: Project): Observable<Project> {
    // this will change
    return this.http.put<Project>(`${this.url}/${project._id}`, project);
  }

  deleteStep(project: Project): Observable<Project> {
    // this will change
    return this.http.put<Project>(`${this.url}/${project._id}`, project);
  }
}
