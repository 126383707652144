import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'trackerMetricValue',
  standalone: true,
})
export class TrackerMetricValuePipe implements PipeTransform {
  transform(value: string, type: string): unknown {
    if (type === 'boolean' && value === '1') {
      return 'Yes';
    }
    if (type === 'boolean' && value === '0') {
      return 'No';
    }

    return value;
  }
}
