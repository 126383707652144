import {getParamsKey} from 'src/app/reporting-new/shared/get-params-key';
import {ReportingAction, ReportingActionType} from './reporting.action';
import {ReportingState, initialReportingState} from './reporting.state';

export function reportingReducer(
  state: ReportingState = initialReportingState,
  action: ReportingAction
): ReportingState {
  switch (action.type) {
    case ReportingActionType.UPDATE_REPORTING_TRACKERS_PARAMS:
      return {...state, reportingTrackersParams: action.payload.reportingTrackersParams};
    case ReportingActionType.GET_TRACKERS_REPORT_SUCCESS: {
      const key = getParamsKey(action.payload.params);
      const trackersReportMap = {...state.trackersReportMap, [key]: action.payload.trackersReport};
      return {...state, trackersReportMap};
    }
    case ReportingActionType.GET_OBJECTS_REPORT_SUCCESS:
      return {...state, reportingObjectEntries: action.payload.reportingObjectEntries};
    case ReportingActionType.CLEAR:
      return {
        reportingTrackersParams: null,
        trackersReportMap: {},
        reportingObjectEntries: null,
      };
    default:
      return state;
  }
}
