import {Sked} from 'src/app/current-sked/shared/model/sked';
import {CurrentSkedTabOptions} from 'src/app/current-sked/task-list/shared/current-sked-tab-options';
import {CurrentSkedTask} from '../../model/task';

export interface CurrentSkedState {
  userSked: Sked;
  teamSked: Sked;
  allSkeds: Sked[];
  headerLoading: boolean;
  task: CurrentSkedTask;
  selectedSkedType: SkedType;
  activeTab: CurrentSkedTabOptions;
  updatedTask: CurrentSkedTask;
  // When updating a boolean metric value in the current sked, the initial displayed value is null. only after a selection is made does the value show, thus keeping track of which values have been touched is needed
  metricUpdateValueChosenMap: Record<string, boolean>; // key is the metric id
}

export interface SkedType {
  id: string;
  type: 'user' | 'team' | 'all';
}

export const initialCurrentSkedState: CurrentSkedState = {
  userSked: null,
  teamSked: null,
  allSkeds: null,
  headerLoading: false,
  task: null,
  selectedSkedType: {id: '', type: 'user'},
  activeTab: CurrentSkedTabOptions.Sked,
  updatedTask: {} as CurrentSkedTask,
  metricUpdateValueChosenMap: {},
};
