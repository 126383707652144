import {combineLatest, filter, map, Observable, take} from 'rxjs';
import {UserTeam} from './../../schedule/shared/scheduler-toolbar/scheduler-toolbar.component';
import {Injectable} from '@angular/core';
import {select, Store} from '@ngrx/store';
import {selectActiveUser, selectActiveUserRole} from 'src/app/core/store/active-user/active-user.selector';
import {User} from 'src/app/core/model/user';
import {selectAllUsers} from 'src/app/core/store/users/users.selector';
import {Team} from 'src/app/teams/shared/model/team';
import {selectAllTeams} from 'src/app/core/store/teams/teams.selector';

@Injectable({
  providedIn: 'root',
})
export class ScheduleService {
  activeUser$: Observable<User> = this.store$.pipe(select(selectActiveUser));
  userRole$: Observable<string> = this.store$.pipe(select(selectActiveUserRole)).pipe(map(role => role?.toLowerCase()));
  users$: Observable<User[]> = this.store$.pipe(select(selectAllUsers));
  teams$: Observable<Team[]> = this.store$.pipe(select(selectAllTeams));

  constructor(private store$: Store) {}

  getUserAndTeamList(): Observable<{users: UserTeam[]; teams: UserTeam[]}> {
    return combineLatest([this.activeUser$, this.userRole$, this.users$, this.teams$]).pipe(
      filter(([activeUser, role, users, teams]) => !!activeUser && !!role && !!users && !!teams),
      take(1),
      map(([activeUser, role, users, teams]) => {
        const userTeamList = {users: [], teams: []};
        if (role?.toLowerCase() === 'admin') {
          userTeamList['users'] = users.map((user: User) => ({label: user.username, id: user._id}));
          userTeamList['teams'] = teams.map((team: Team) => ({label: team.name, id: team.id}));
        } else {
          userTeamList['users'] = [{label: activeUser?.username, id: activeUser?._id}];
          userTeamList['teams'] = teams
            .filter((team: Team) => activeUser.teams.includes(team.id))
            .map((team: Team) => ({label: team.name, id: team.id}));
        }
        return userTeamList;
      })
    );
  }
}
