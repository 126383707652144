import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {ArrayIncludesPipe} from './array-includes.pipe';
import {BackgroundClassesPipe} from './background-classes.pipe';
import {HexToRgbaPipe} from './color/hexToRgba.pipe';
import {DayOfWeekInMonthPipe} from './date/day-of-week-in-month.pipe';
import {MomentPipe} from './date/moment.pipe';
import {TimeAgoPipe} from './date/time-ago.pipe';
import {DisplayFormatPipe} from './display-format.pipe';
import {IconNamePipe} from './icon-name.pipe';
import {IncludesNumberPipe} from './includes-number.pipe';
import {LogPipe} from './log.pipe';
import {MultiWordTitlecasePipe} from './multi-word-titlecase.pipe';
import {NoSanitizerPipe} from './no-sanitizer.pipe';
import {OrdinalPipe} from './number/ordinal.pipe';
import {ObjectAttributeTypeDisplayPipe} from './objects/object-attribute-type-display.pipe';
import {ReplacePipe} from './replace.pipe';
import {SplitPipe} from './split.pipe';
import {StringifyPipe} from './stringify.pipe';
import {TimeFormatPipe, WeekdayFormatPipe} from './time.pipe';
import {UnCamelCasePipe} from './uncamelcase.pipe';
import {UserAvatarPipe} from './user-avatar.pipe';
import {WithLoadingPipe} from './with-loading.pipe';
import {CurrentSkedPriorityColorPipe} from './current-sked-priority-color.pipe';
import {CurrentSkedStatusNameDisplayPipe} from './current-sked-status-name-display.pipe';
import {CurrentSkedTaskStatusColorPipe} from './current-sked-task-status-color.pipe';
import {UrlPrefixerPipe} from './url-prefixer.pipe';
import {CurrentSkedTimeDisplayPipe} from './current-sked-time-display.pipe';
import {ConvertUtcToTimezonePipe} from './convert-utc-to-timezone.pipe';
import {MinutesToHoursAndMinutesPipe} from './minutes-to-hours-and-minutes.pipe';
import {ScheduleMonthEventDisplayPipe} from './schedule-month-event-display.pipe';
import {IsANumberPipe} from './is-a-number.pipe';
import {NumberToBooleanPipe} from './number-to-boolean.pipe';
import {IsDateTodayPipe} from './is-date-today.pipe';
import {OperatorDisplayPipe} from './operator-display.pipe';

@NgModule({
  imports: [CommonModule],
  declarations: [
    BackgroundClassesPipe,
    ReplacePipe,
    DisplayFormatPipe,
    UnCamelCasePipe,
    TimeFormatPipe,
    WeekdayFormatPipe,
    WithLoadingPipe,
    LogPipe,
    StringifyPipe,
    ArrayIncludesPipe,
    MomentPipe,
    DayOfWeekInMonthPipe,
    OrdinalPipe,
    TimeAgoPipe,
    SplitPipe,
    HexToRgbaPipe,
    UserAvatarPipe,
    IconNamePipe,
    NoSanitizerPipe,
    MultiWordTitlecasePipe,
    ObjectAttributeTypeDisplayPipe,
    IncludesNumberPipe,
    CurrentSkedPriorityColorPipe,
    CurrentSkedStatusNameDisplayPipe,
    CurrentSkedTaskStatusColorPipe,
    UrlPrefixerPipe,
    CurrentSkedTimeDisplayPipe,
    ConvertUtcToTimezonePipe,
    MinutesToHoursAndMinutesPipe,
    ScheduleMonthEventDisplayPipe,
    IsANumberPipe,
    NumberToBooleanPipe,
    IsDateTodayPipe,
    OperatorDisplayPipe,
  ],
  exports: [
    BackgroundClassesPipe,
    ReplacePipe,
    DisplayFormatPipe,
    UnCamelCasePipe,
    TimeFormatPipe,
    WeekdayFormatPipe,
    WithLoadingPipe,
    LogPipe,
    StringifyPipe,
    ArrayIncludesPipe,
    MomentPipe,
    DayOfWeekInMonthPipe,
    OrdinalPipe,
    TimeAgoPipe,
    SplitPipe,
    HexToRgbaPipe,
    UserAvatarPipe,
    IconNamePipe,
    NoSanitizerPipe,
    MultiWordTitlecasePipe,
    ObjectAttributeTypeDisplayPipe,
    IncludesNumberPipe,
    CurrentSkedPriorityColorPipe,
    CurrentSkedStatusNameDisplayPipe,
    CurrentSkedTaskStatusColorPipe,
    UrlPrefixerPipe,
    CurrentSkedTimeDisplayPipe,
    ConvertUtcToTimezonePipe,
    MinutesToHoursAndMinutesPipe,
    ScheduleMonthEventDisplayPipe,
    IsANumberPipe,
    NumberToBooleanPipe,
    IsDateTodayPipe,
    OperatorDisplayPipe,
  ],
})
export class PipesModule {}
