import {ChangeDetectionStrategy, Component, EventEmitter, Output} from '@angular/core';
import {MatIconModule} from '@angular/material/icon';

@Component({
  selector: 'oph-chip-button',
  standalone: true,
  imports: [MatIconModule],
  templateUrl: './oph-chip-button.component.html',
  styleUrl: './oph-chip-button.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OphChipButtonComponent {
  @Output() buttonClick = new EventEmitter();
}
