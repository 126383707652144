import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostBinding,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import {Router} from '@angular/router';
import {Store, select} from '@ngrx/store';
import {BehaviorSubject, Observable, Subscription, combineLatest, timer} from 'rxjs';
import {filter, map, take, withLatestFrom} from 'rxjs/operators';
import {CurrentSkedTask, Subtask} from 'src/app/core/model/task';
import {User} from 'src/app/core/model/user';
import {selectActiveUser} from 'src/app/core/store/active-user/active-user.selector';
import {
  selectCurrentSkedDetailTask,
  selectCurrentSkedSkeds,
} from 'src/app/core/store/current-sked/current-sked.selector';
import {GetLensSettingsAction} from 'src/app/core/store/lenses/lenses.action';
import {GetLocationsAction} from 'src/app/core/store/locations/locations.action';
import {selectLocationsMap} from 'src/app/core/store/locations/locations.selector';
import {selectProjectsMap, selectProjectViewTaskId} from 'src/app/core/store/projects/projects.selector';
import {selectRouterParam} from 'src/app/core/store/router/router.selector';
import {LensSettings} from 'src/app/lenses/lens-settings';
import {LensSetting} from 'src/app/lenses/shared/model/lens-setting';
import {Location} from 'src/app/locations/shared/model/location';
import {Project} from 'src/app/core/model/project';
import {MessageService} from 'src/app/services/message.service';
import {CurrentSkedService} from '../shared/current-sked.service';
import {AvailableTaskActions} from '../shared/model/available-task-actions';
import {Sked} from '../shared/model/sked';
import {EMPTY_TASK_LOADING_STATE, TaskLoadingState} from '../shared/model/task-loading-state';
import {TaskStatusActions} from '../shared/task-status-actions';
import {TaskStatuses} from '../shared/task-statuses';
import {getSkedTimeStatus} from '../shared/utils/get-sked-time-status';
import {
  ClearCurrentSkedTaskAction,
  ClearCurrentSkedValueChosen,
  CurrentSkedChangeSubtaskStatusAction,
  CurrentSkedChangeTaskStatusAction,
  CurrentSkedUpdateSubtaskMetricAction,
  GetCurrentSkedTaskAction,
} from './../../core/store/current-sked/current-sked.action';
import {selectCombinedLensSettingsMap, selectLensSettingsMap} from './../../core/store/lenses/lenses.selector';
import {GetProjectsAction} from 'src/app/core/store/projects/projects.action';
import {GetCoinTypesAction} from 'src/app/core/store/rewards/rewards.action';
import {RewardCoin} from 'src/app/core/model/reward';
import {selectCoinTypesMap} from 'src/app/core/store/rewards/rewards.selector';

@Component({
  selector: 'current-sked-task-detail',
  templateUrl: './current-sked-task-detail.component.html',
  styleUrls: ['./current-sked-task-detail.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CurrentSkedTaskDetailComponent implements OnInit, OnDestroy {
  @HostBinding('class') hostClasses = 'oph-feature-layout oph-feature-padding';

  @Input() notInSked: boolean; // if this component is used in project viewer, this will be true

  @Output() notInSkedBack = new EventEmitter();
  @Output() notInSkedGet = new EventEmitter();

  subscriptions = new Subscription();
  metricTimerSubscription: Subscription;

  user$: Observable<User>;
  skedId$: Observable<string>;
  skeds$: Observable<Sked[]>;
  sked$: Observable<Sked>;
  skedTimeStatus$: Observable<string>;
  skedIndex$: Observable<string>;
  taskId$: Observable<string>;
  storedTaskId$: Observable<string>;
  task$: Observable<CurrentSkedTask>;
  projectsMap$: Observable<Record<string, Project>>;
  locationsMap$: Observable<Record<string, Location>>;
  lensSettingsMap$: Observable<Record<LensSettings, LensSetting>>;
  combinedLensSettingsMap$: Observable<Record<LensSettings, string>>;
  ableToCompleteSubtaskIndex$: Observable<number>;
  availableTaskActions$: Observable<AvailableTaskActions>;
  coinTypesMap$: Observable<Record<string, RewardCoin>> = this.store$.pipe(select(selectCoinTypesMap));

  loading$ = new BehaviorSubject<TaskLoadingState>(EMPTY_TASK_LOADING_STATE);
  expandedSubtaskIndex$ = new BehaviorSubject<number>(null);
  selectedSubtask$ = new BehaviorSubject<Subtask>(null);
  selectedSubtaskIndex$ = new BehaviorSubject<number>(null);

  descriptionOpen: boolean = false;
  taskStatuses = TaskStatuses;

  constructor(
    private messageService: MessageService,
    private router: Router,
    private store$: Store,
    private currentSkedService: CurrentSkedService
  ) {}

  ngOnInit() {
    this.store$.dispatch(new GetProjectsAction({}));
    this.store$.dispatch(new GetLensSettingsAction({}));
    this.store$.dispatch(new GetLocationsAction({}));
    this.store$.dispatch(new GetCoinTypesAction({}));

    this.user$ = this.store$.select(selectActiveUser);
    this.skeds$ = this.store$.pipe(select(selectCurrentSkedSkeds));
    this.sked$ = this.getSked();
    this.skedTimeStatus$ = this.store$.pipe(select(selectRouterParam('skedStatus')));
    this.skedIndex$ = this.store$.pipe(select(selectRouterParam('skedIndex')));
    this.skedId$ = this.store$.pipe(select(selectRouterParam('skedId')));
    this.taskId$ = this.store$.pipe(select(selectRouterParam('taskId')));
    this.storedTaskId$ = this.store$.pipe(select(selectProjectViewTaskId));
    this.task$ = this.store$.pipe(select(selectCurrentSkedDetailTask));
    this.projectsMap$ = this.store$.pipe(select(selectProjectsMap));
    this.locationsMap$ = this.store$.pipe(select(selectLocationsMap));
    this.lensSettingsMap$ = this.store$.pipe(select(selectLensSettingsMap));
    this.combinedLensSettingsMap$ = this.store$.pipe(select(selectCombinedLensSettingsMap));
    this.ableToCompleteSubtaskIndex$ = this.observeCombinedLensSettingsMap();
    this.availableTaskActions$ = this.observeTask();

    this.getTask();
    this.subscriptions.add(this.subscribeToTask());
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
    this.store$.dispatch(new ClearCurrentSkedTaskAction());
    // This clears the state used in the tracker metric update component
    this.store$.dispatch(new ClearCurrentSkedValueChosen());
  }

  subscribeToTask(): Subscription {
    return this.task$.pipe(filter(t => !!t)).subscribe(task => {
      if (task?.status === this.taskStatuses.Completed) {
        if (this.notInSked) {
          return;
        }
        this.router.navigate(['current-sked']);
      }
    });
  }

  observeTask(): Observable<AvailableTaskActions> {
    return this.task$.pipe(
      filter(task => !!task),
      withLatestFrom(this.user$, this.combinedLensSettingsMap$, this.skedTimeStatus$),
      map(([task, user, lensMap, skedTimeStatus]) => {
        return this.currentSkedService.getAvailableTaskActions(task, user, lensMap, skedTimeStatus);
      })
    );
  }

  observeCombinedLensSettingsMap(): Observable<number> {
    return combineLatest([this.task$, this.combinedLensSettingsMap$]).pipe(
      map(([task, settingsMap]) => {
        if (!task || !settingsMap) {
          return;
        }
        if (!task.authorizationObject.isAuthorized) {
          return -1;
        }
        if (settingsMap[LensSettings.CanSkipSubtasks] === 'false') {
          let index;
          task.subtasks.forEach((subtask, i) => {
            if (subtask.complete) {
              index = i + 1;
            }
          });
          return index || 0;
        } else {
          return task.subtasks.length;
        }
      })
    );
  }

  getTask() {
    combineLatest([this.taskId$, this.storedTaskId$])
      .pipe(
        map(([id, storedId]) => id || storedId),
        filter(id => !!id),
        take(1)
      )
      .subscribe(taskId => {
        this.store$.dispatch(
          new GetCurrentSkedTaskAction({
            taskId,
            onSuccess: (task: CurrentSkedTask) => this.onGetTaskSuccess(task),
            onFailure: () => this.onGetTaskFailure(),
          })
        );
      });
  }

  onGetTaskSuccess(task: CurrentSkedTask) {
    this.loading$.next({...this.loading$.value, task: false, metricId: '', subtaskIndex: null, action: false});
    this.setExpandedSubtaskIndex(task);
  }

  onGetTaskFailure() {
    this.loading$.next({...this.loading$.value, task: false});
    this.messageService.add('There was a problem getting this task.');
    if (this.notInSked) {
      return this.notInSkedBack.emit();
    }
    timer(10).subscribe(() => this.router.navigate(['current-sked']));
  }

  onExpandedSubtaskIndexChange(i: number) {
    const index = i === this.expandedSubtaskIndex$.value ? null : i;
    this.expandedSubtaskIndex$.next(index);
  }

  setExpandedSubtaskIndex(task: CurrentSkedTask) {
    // checks for first subtask not done, if it has updateable attribute, open subtask to show attribute
    const result =
      task.status === this.taskStatuses.Unclaimed ? -1 : task.subtasks.findIndex(subtask => !subtask.complete);
    const doneIndex = result !== -1 ? result : null;
    const expandedSubtaskIndex = task.subtasks[doneIndex]?.metricId ? doneIndex : null;
    setTimeout(() => {
      // set timeout needed to trigger subtask accordion animation
      this.expandedSubtaskIndex$.next(expandedSubtaskIndex);
    }, 1);
  }

  getSked(): Observable<Sked> {
    return this.skeds$.pipe(
      filter(skeds => !!skeds.length),
      map(skeds => skeds.find(sked => sked.current))
    );
  }

  getSkedStatus(): Observable<string> {
    return this.sked$.pipe(map(sked => getSkedTimeStatus(sked?.startDateTime, sked?.endDateTime)));
  }

  onBack() {
    if (this.notInSked) {
      return this.notInSkedBack.emit();
    }
    this.router.navigate(['current-sked']);
  }

  onSubtaskBack() {
    this.selectedSubtask$.next(null);
    this.selectedSubtaskIndex$.next(null);
  }

  onSubtask(subtask: Subtask, index: number) {
    this.selectedSubtask$.next(subtask);
    this.selectedSubtaskIndex$.next(index);
  }

  onUpdateTaskStatus(actionType: TaskStatusActions) {
    combineLatest([this.task$, this.skedId$])
      .pipe(take(1))
      .subscribe(([task, skedId]) => {
        this.loading$.next({...this.loading$.value, action: true});
        this.store$.dispatch(
          new CurrentSkedChangeTaskStatusAction({
            id: task._id,
            actionType,
            skedId,
            onSuccess: task => {
              this.onActionSuccess(actionType);
            },
            onFailure: () => this.onActionFailure(actionType),
          })
        );
      });
  }

  onActionSuccess(actionType: TaskStatusActions) {
    this.notInSkedGet.emit();
    this.loading$.next({...this.loading$.value, action: false});
  }

  onActionFailure(actionType: TaskStatusActions) {
    const statusText = this.getFailureStatusText(actionType);
    this.messageService.add(`There was a problem ${statusText} the task.`);
    this.loading$.next({...this.loading$.value, action: false});
  }

  getSuccessStatusText(status: string): string {
    if (status === TaskStatuses.InProgress) {
      return 'started';
    }
    return status;
  }

  getFailureStatusText(actionType: TaskStatusActions): string {
    switch (actionType) {
      case TaskStatusActions.Start:
        return 'starting';
      case TaskStatusActions.Pause:
        return 'pausing';
      case TaskStatusActions.Complete:
        return 'completing';
    }
  }

  onUpdateMetricValue(value: string, subtask: Subtask, subtaskIndex: number, taskId: string) {
    this.loading$.next({...this.loading$.value, metricId: subtask.metricId, metricStatus: 'loading'});
    this.store$.dispatch(
      new CurrentSkedUpdateSubtaskMetricAction({
        // Makes sure the value is a string
        value: String(value),
        trackerId: subtask.trackerId,
        metricId: subtask.metricId,
        taskId,
        onSuccess: task => this.onUpdateMetricValueSuccess(task),
        onFailure: () => this.onUpdateMetricValueFailure(),
      })
    );
  }

  onUpdateMetricValueSuccess(task: CurrentSkedTask) {
    this.loading$.next({...this.loading$.value, metricStatus: 'success'});
    if (this.selectedSubtask$.value) {
      const subtaskIndex = task.subtasks.findIndex(subtask => subtask._id === this.selectedSubtask$.value._id);
      if (subtaskIndex || subtaskIndex === 0) {
        this.selectedSubtask$.next(task.subtasks[subtaskIndex]);
      } else {
        this.messageService.add('Subtask was not found. Defaulting to first subtask.');
      }
    }
    // Unsubscribe from the previous timer if it's still active
    if (this.metricTimerSubscription) {
      this.metricTimerSubscription.unsubscribe();
    }
    this.metricTimerSubscription = timer(2000).subscribe(() =>
      this.loading$.next({...this.loading$.value, metricId: '', metricStatus: ''})
    );
  }

  onUpdateMetricValueFailure() {
    this.loading$.next({...this.loading$.value, metricId: ''});
    this.messageService.add('There was a problem updating the metric value.');
  }

  onUpdateSubtaskStatus(complete: boolean, subtask: Subtask, subtaskIndex: number) {
    this.loading$.next({...this.loading$.value, subtaskIndex});
    this.task$.pipe(take(1)).subscribe(task => {
      this.store$.dispatch(
        new CurrentSkedChangeSubtaskStatusAction({
          taskId: task._id,
          subtaskId: subtask._id,
          complete,
          onSuccess: (task: CurrentSkedTask) => {
            this.onChangeSubtaskStatusSuccess(task);
            const index = task.subtasks.findIndex(subtask => !subtask.complete);
            if (this.selectedSubtask$.value) {
              this.selectedSubtask$.next(task.subtasks[index]);
            }
          },
          // onSuccess: (task: CurrentSkedTask) => !preventSuccess && this.onChangeSubtaskStatusSuccess(task),
          onFailure: () => this.onChangeSubtaskStatusFailure(),
        })
      );
    });
  }

  onChangeSubtaskStatusSuccess(task: CurrentSkedTask) {
    // figure out what's being sent from backend to need this
    if (task?.error) {
      this.messageService.add(task.error);
    }
    this.loading$.next({...this.loading$.value, subtaskIndex: null});
    this.setExpandedSubtaskIndex(task);
    // this.notInSkedGet.emit();
  }

  onChangeSubtaskStatusFailure() {
    this.messageService.add('There was a problem updating the subtask.');
    this.loading$.next({...this.loading$.value, subtaskIndex: null});
  }
}
