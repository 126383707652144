import {Action as ActionModel} from '@ngrx/store';
import {Action} from '../../../actions/shared/model/action';

export enum ActionsActionType {
  GET = '[Actions] Get',
  GET_SUCCESS = '[Actions] Get :: Success',

  CREATE = '[Actions] Create',
  CREATE_SUCCESS = '[Actions] Create :: Success',

  UPDATE = '[Actions] Update',
  UPDATE_SUCCESS = '[Actions] Update :: Success',

  DELETE = '[Actions] Delete',
  DELETE_SUCCESS = '[Actions] Delete :: Success',

  CLEAR = '[Actions] Clear',
}

export class GetActionsAction implements ActionModel {
  public readonly type = ActionsActionType.GET;

  public constructor(
    public payload: {
      onSuccess?: (actions: Action[]) => void;
      onFailure?: (error: Error) => void;
    }
  ) {}
}

export class GetActionsSuccessAction implements ActionModel {
  public readonly type = ActionsActionType.GET_SUCCESS;

  public constructor(public payload: {actions: Action[]}) {}
}

export class CreateActionAction implements ActionModel {
  public readonly type = ActionsActionType.CREATE;

  public constructor(
    public payload: {
      action: Action;
      onSuccess?: (action: Action) => void;
      onFailure?: (error: Error) => void;
    }
  ) {}
}

export class CreateActionSuccessAction implements ActionModel {
  public readonly type = ActionsActionType.CREATE_SUCCESS;

  public constructor(public payload: {action: Action}) {}
}

export class UpdateActionAction implements ActionModel {
  public readonly type = ActionsActionType.UPDATE;

  public constructor(
    public payload: {
      action: Action;
      onSuccess?: (action: Action) => void;
      onFailure?: (error: Error) => void;
    }
  ) {}
}

export class UpdateActionSuccessAction implements ActionModel {
  public readonly type = ActionsActionType.UPDATE_SUCCESS;

  public constructor(public payload: {action: Action}) {}
}

export class DeleteActionAction implements ActionModel {
  public readonly type = ActionsActionType.DELETE;

  public constructor(
    public payload: {
      id: string;
      onSuccess?: () => void;
      onFailure?: (error: Error) => void;
    }
  ) {}
}

export class DeleteActionSuccessAction implements ActionModel {
  public readonly type = ActionsActionType.DELETE_SUCCESS;
}

export class ClearActionsAction implements ActionModel {
  public readonly type = ActionsActionType.CLEAR;
}

export type ActionsAction =
  | GetActionsAction
  | GetActionsSuccessAction
  | CreateActionAction
  | CreateActionSuccessAction
  | UpdateActionAction
  | UpdateActionSuccessAction
  | DeleteActionAction
  | DeleteActionSuccessAction
  | ClearActionsAction;
