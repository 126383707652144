import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {Sked} from 'src/app/current-sked/shared/model/sked';
import {TaskStatusActions} from 'src/app/current-sked/shared/task-status-actions';
import {environment} from 'src/environments/environment';
import {CurrentSkedTask} from '../model/task';
import {SkedType} from '../store/current-sked/current-sked.state';

@Injectable({
  providedIn: 'root',
})
export class CurrentSkedApiService {
  private readonly url = `${environment.apiUrl}`;

  constructor(private http: HttpClient) {}

  get(skedType: SkedType): Observable<Sked> {
    return this.http.get<Sked>(`${this.url}/execution/${skedType.type}/${skedType.id}`);
  }

  getAllSkeds(): Observable<Sked[]> {
    return this.http.get<Sked[]>(`${this.url}/execution/day`);
  }

  getCurrentSkedTask(taskId: string): Observable<CurrentSkedTask> {
    return this.http.get<CurrentSkedTask>(`${this.url}/execution/task/${taskId}`);
  }

  changeTaskStatus(taskId: string, type: TaskStatusActions, skedId?: string): Observable<CurrentSkedTask> {
    return this.http.patch<CurrentSkedTask>(
      `${this.url}/execution/${type}/${taskId}`,
      type === TaskStatusActions.Hide ? {skedId} : {}
    );
  }

  changeSubTaskStatus(taskId: string, subtaskId: string, complete: boolean): Observable<CurrentSkedTask> {
    const payload = {
      taskId,
      subtaskId,
      complete,
    };
    return this.http.patch<CurrentSkedTask>(`${this.url}/execution/subtask`, payload);
  }

  updateSubtaskMetricValue(
    value: string,
    trackerId: string,
    metricId: string,
    taskId: string
  ): Observable<CurrentSkedTask> {
    const payload = {
      value,
      trackerId,
      metricId,
      taskId,
    };
    return this.http.patch<CurrentSkedTask>(`${this.url}/execution/subtask/tracker`, payload);
  }

  updateCurrentSked(sked: Sked): Observable<Sked> {
    return this.http.put<Sked>(`${this.url}/skeds/${sked._id}`, sked);
  }
}
