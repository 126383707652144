import {ReportingTrackersParams} from 'src/app/core/model/reporting';
import * as moment from 'moment-timezone';
import {Project} from 'src/app/core/model/project';

export function getInitialReportingTrackerParams(
  timezone: string,
  projectsMap: Record<string, Project>
): ReportingTrackersParams {
  const startDate = moment().tz(timezone).startOf('isoWeek').format();
  const endDate = moment().tz(timezone).endOf('isoWeek').format();
  let projectId: string;
  const storedProjectId = sessionStorage.getItem('ophStoredProjectId');

  if (storedProjectId && projectsMap[storedProjectId]) {
    projectId = storedProjectId;
  } else {
    projectId = Object.keys(projectsMap)[0];
  }

  return {
    page: 1,
    pageSize: 10,
    startDate,
    endDate,
    projectId,
  };
}
