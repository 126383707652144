<button (click)="onCheckbox()">
  @if (textOrientation === 'left') {
    <span [ngStyle]="textStyle" #contentContainer>
      {{ text }}
    </span>
  }

  <div
    class="checkbox"
    [style.width.px]="width"
    [style.height.px]="width"
    [class]="control?.value || checked ? 'checked ' + color : ''"
  >
    <mat-icon
      svgIcon="checkbox-check"
      [style.fontSize.px]="width - 2"
      [style.width.px]="width - 2"
      [style.height.px]="width - 2"
    ></mat-icon>
  </div>

  @if (textOrientation === 'right') {
    <span [ngStyle]="textStyle" #contentContainer>
      {{ text }}
    </span>
  }
</button>
