import {HttpErrorResponse} from '@angular/common/http';
import {Action} from '@ngrx/store';
import {ListSearchParams} from '../../model/list-search-params';
import {Reward, RewardCoin, RewardHistory, RewardPagination} from '../../model/reward';

export enum RewardsActionType {
  GET_MANAGE = '[Rewards] Get Manage',
  GET_MANAGE_SUCCESS = '[Rewards] Get Manage :: Success',

  GET_MY_REWARDS = '[Rewards] Get My Rewards',
  GET_MY_REWARDS_SUCCESS = '[Rewards] Get My Rewards :: Success',

  GET_ADMIN_HISTORY = '[Rewards] Get Admin History',
  GET_ADMIN_HISTORY_SUCCESS = '[Rewards] Get Admin History :: Success',

  GET_USER_HISTORY = '[Rewards] Get User History',
  GET_USER_HISTORY_SUCCESS = '[Rewards] Get User History :: Success',

  GET_SINGLE_REWARD = '[Rewards] Get Single Reward',
  GET_SINGLE_REWARD_SUCCESS = '[Rewards] Get Single Reward :: Success',

  CREATE = '[Rewards] Create',
  CREATE_SUCCESS = '[Rewards] Create :: Success',

  UPDATE = '[Rewards] Update',
  UPDATE_SUCCESS = '[Rewards] Update :: Success',

  DELETE = '[Rewards] Delete',
  DELETE_SUCCESS = '[Rewards] Delete :: Success',

  GET_COIN_TYPES = '[Rewards] Get Coin Types',
  GET_COIN_TYPES_SUCCESS = '[Rewards] Get Coin Types :: Success',

  UPDATE_MANAGE_REWARDS_COIN_EXPANDED_MAP = '[Rewards] Update Manage Rewards Coin Expanded Map',
  UPDATE_MANAGE_REWARDS_REWARD_EXPANDED_MAP = '[Rewards] Update Manage Rewards Reward Expanded Map',
  UPDATE_MY_REWARDS_EXPANDED_MAP = '[Rewards] Update My Rewards Expanded Map',
  UPDATE_REWARD_HISTORY_EXPANDED_MAP = '[Rewards] Reward History Expanded Map',

  FULFILL_REWARD = '[Rewards] Fulfill Reward',
  FULFILL_REWARD_SUCCESS = '[Rewards] Fulfill Reward :: Success',
  CLAIM = '[Rewards] Claim',

  UPDATE_HEADER_LOADING = '[Rewards] Update Header Loading',

  CLEAR = '[Rewards] Clear',
}

export class GetManageRewardsAction implements Action {
  public readonly type = RewardsActionType.GET_MANAGE;

  public constructor(
    public payload: {
      onSuccess?: (manageRewards: RewardCoin[]) => void;
      onFailure?: (error: HttpErrorResponse) => void;
    }
  ) {}
}

export class GetManageRewardsSuccessAction implements Action {
  public readonly type = RewardsActionType.GET_MANAGE_SUCCESS;

  public constructor(public payload: {manageRewards: RewardCoin[]}) {}
}

export class GetMyRewardsAction implements Action {
  public readonly type = RewardsActionType.GET_MY_REWARDS;

  public constructor(
    public payload: {
      onSuccess?: () => void;
      onFailure?: (error: HttpErrorResponse) => void;
    }
  ) {}
}

export class GetMyRewardsSuccessAction implements Action {
  public readonly type = RewardsActionType.GET_MY_REWARDS_SUCCESS;

  public constructor(public payload: {myRewards: RewardCoin[]}) {}
}

export class GetRewardsUserHistoryAction implements Action {
  public readonly type = RewardsActionType.GET_USER_HISTORY;

  public constructor(
    public payload: {
      params: ListSearchParams;
      onSuccess?: () => void;
      onFailure?: (error: HttpErrorResponse) => void;
    }
  ) {}
}

export class GetRewardsUserHistorySuccessAction implements Action {
  public readonly type = RewardsActionType.GET_USER_HISTORY_SUCCESS;

  public constructor(public payload: {userHistory: RewardHistory}) {}
}

export class GetRewardsAdminHistoryAction implements Action {
  public readonly type = RewardsActionType.GET_ADMIN_HISTORY;

  public constructor(
    public payload: {
      params: ListSearchParams;
      onSuccess?: () => void;
      onFailure?: (error: HttpErrorResponse) => void;
    }
  ) {}
}

export class GetRewardsAdminHistorySuccessAction implements Action {
  public readonly type = RewardsActionType.GET_ADMIN_HISTORY_SUCCESS;

  public constructor(public payload: {adminHistory: RewardPagination}) {}
}

export class GetSingleRewardAction implements Action {
  public readonly type = RewardsActionType.GET_SINGLE_REWARD;

  public constructor(
    public payload: {
      rewardId: string;
      onSuccess?: (reward: Reward) => void;
      onFailure?: (error: HttpErrorResponse) => void;
    }
  ) {}
}

export class GetSingleRewardSuccessAction implements Action {
  public readonly type = RewardsActionType.GET_SINGLE_REWARD_SUCCESS;

  public constructor(public payload: {reward: Reward}) {}
}

export class CreateRewardAction implements Action {
  public readonly type = RewardsActionType.CREATE;

  public constructor(
    public payload: {
      reward: Reward;
      onSuccess?: () => void;
      onFailure?: (error: HttpErrorResponse) => void;
    }
  ) {}
}

export class CreateRewardSuccessAction implements Action {
  public readonly type = RewardsActionType.CREATE_SUCCESS;

  public constructor(public payload: {reward: Reward}) {}
}

export class UpdateRewardAction implements Action {
  public readonly type = RewardsActionType.UPDATE;

  public constructor(
    public payload: {
      rewardId: string;
      reward: Reward;
      onSuccess?: (reward: Reward) => void;
      onFailure?: (error: HttpErrorResponse) => void;
    }
  ) {}
}

export class UpdateRewardSuccessAction implements Action {
  public readonly type = RewardsActionType.UPDATE_SUCCESS;

  public constructor(public payload: {reward: Reward}) {}
}

export class DeleteRewardAction implements Action {
  public readonly type = RewardsActionType.DELETE;

  public constructor(
    public payload: {
      id: string;
      onSuccess?: () => void;
      onFailure?: (error: HttpErrorResponse) => void;
    }
  ) {}
}

export class DeleteRewardSuccessAction implements Action {
  public readonly type = RewardsActionType.DELETE_SUCCESS;
}

export class GetCoinTypesAction implements Action {
  public readonly type = RewardsActionType.GET_COIN_TYPES;

  public constructor(
    public payload: {
      onSuccess?: () => void;
      onFailure?: (error: HttpErrorResponse) => void;
    }
  ) {}
}

export class GetCoinTypesSuccessAction implements Action {
  public readonly type = RewardsActionType.GET_COIN_TYPES_SUCCESS;

  public constructor(public payload: {coinTypes: RewardCoin[]}) {}
}

export class UpdateManageRewardsCoinExpandedMapAction implements Action {
  public readonly type = RewardsActionType.UPDATE_MANAGE_REWARDS_COIN_EXPANDED_MAP;

  public constructor(public payload: {name: string; open: boolean}) {}
}

export class UpdateManageRewardsRewardExpandedMapAction implements Action {
  public readonly type = RewardsActionType.UPDATE_MANAGE_REWARDS_REWARD_EXPANDED_MAP;

  public constructor(public payload: {key: string; open: boolean}) {}
}

export class UpdateMyRewardsExpandedMapAction implements Action {
  public readonly type = RewardsActionType.UPDATE_MY_REWARDS_EXPANDED_MAP;

  public constructor(public payload: {key: string; open: boolean}) {}
}

export class UpdateRewardHistoryExpandedMapAction implements Action {
  public readonly type = RewardsActionType.UPDATE_REWARD_HISTORY_EXPANDED_MAP;

  public constructor(public payload: {key: string; open: boolean}) {}
}

export class FulfillRewardAction implements Action {
  public readonly type = RewardsActionType.FULFILL_REWARD;

  public constructor(
    public payload: {
      rewardId: string;
      assignedToArrayId: string;
      onSuccess?: (reward: Reward) => void;
      onFailure?: (error: HttpErrorResponse) => void;
    }
  ) {}
}

export class FulfillRewardSuccessAction implements Action {
  public readonly type = RewardsActionType.FULFILL_REWARD_SUCCESS;

  public constructor(public payload: {reward: unknown}) {}
}

export class ClaimRewardAction implements Action {
  public readonly type = RewardsActionType.CLAIM;

  public constructor(
    public payload: {
      rewardId: string;
      assignedToArrayId: string;
      onSuccess?: () => void;
      onFailure?: (error: HttpErrorResponse) => void;
    }
  ) {}
}

export class UpdateRewardsHeaderLoading implements Action {
  public readonly type = RewardsActionType.UPDATE_HEADER_LOADING;

  public constructor(public payload: {headerLoading: boolean}) {}
}

export class ClearRewardsAction implements Action {
  public readonly type = RewardsActionType.CLEAR;
}

export type RewardsAction =
  | GetManageRewardsAction
  | GetManageRewardsSuccessAction
  | GetMyRewardsAction
  | GetMyRewardsSuccessAction
  | GetRewardsUserHistoryAction
  | GetRewardsUserHistorySuccessAction
  | GetRewardsAdminHistoryAction
  | GetRewardsAdminHistorySuccessAction
  | GetSingleRewardAction
  | GetSingleRewardSuccessAction
  | CreateRewardAction
  | CreateRewardSuccessAction
  | UpdateRewardAction
  | UpdateRewardSuccessAction
  | DeleteRewardAction
  | DeleteRewardSuccessAction
  | GetCoinTypesAction
  | GetCoinTypesSuccessAction
  | UpdateManageRewardsCoinExpandedMapAction
  | UpdateManageRewardsRewardExpandedMapAction
  | UpdateMyRewardsExpandedMapAction
  | UpdateRewardHistoryExpandedMapAction
  | FulfillRewardAction
  | FulfillRewardSuccessAction
  | UpdateRewardsHeaderLoading
  | ClearRewardsAction;
