import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import {NavigationEnd, Router} from '@angular/router';
import {select, Store} from '@ngrx/store';
import * as moment from 'moment';
import {Observable, Subscription, take} from 'rxjs';
import {User} from 'src/app/core/model/user';
import {UpdateScheduleQueryParams} from 'src/app/core/store/schedule/schedule.action';
import {selectScheduleQueryParams} from 'src/app/core/store/schedule/schedule.selector';
import {GetTeamsAction} from 'src/app/core/store/teams/teams.action';
import {selectTeamsMap} from 'src/app/core/store/teams/teams.selector';
import {GetAllUsersAction} from 'src/app/core/store/users/users.action';
import {selectUsersMap} from 'src/app/core/store/users/users.selector';
import {ScheduleQueryParams} from 'src/app/schedule/shared/model/schedule-query-params';
import {TimezoneService} from 'src/app/services/timezone.service';
import {ScheduleService} from 'src/app/shared/services/schedule.service';
import {Team} from 'src/app/teams/shared/model/team';

enum ToggleOptions {
  Auto = 'auto',
  Calendar = 'calendar',
}

enum WeekMonthOptions {
  Week = 'week',
  Month = 'month',
}

export interface UserTeam {
  label: string;
  id: string;
}

@Component({
  selector: 'scheduler-toolbar',
  templateUrl: './scheduler-toolbar.component.html',
  styleUrls: ['./scheduler-toolbar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SchedulerToolbarComponent implements OnInit, OnDestroy, OnChanges {
  @Input() activeUser: User;

  public subscriptions: Subscription = new Subscription();

  queryParams$: Observable<ScheduleQueryParams> = this.store$.select(selectScheduleQueryParams);
  usersMap$: Observable<Record<string, User>> = this.store$.pipe(select(selectUsersMap));
  teamsMap$: Observable<Record<string, Team>> = this.store$.pipe(select(selectTeamsMap));
  userTeamList$: Observable<{users: UserTeam[]; teams: UserTeam[]}>;

  public toggleOptions = ToggleOptions;
  public weekMonthOptions = WeekMonthOptions;
  public toggleOption: string;
  public weekMonthOption: string;
  public userTeamOption: UserTeam;
  public startDate: string;
  public userTeamList: UserTeam[] = [];
  timeZone = this.timeZoneService.getTimeZone();
  queryParams: ScheduleQueryParams;

  constructor(
    private store$: Store,
    private router: Router,
    private timeZoneService: TimezoneService,
    private cdRef: ChangeDetectorRef,
    private scheduleService: ScheduleService
  ) {}

  ngOnInit(): void {
    this.store$.dispatch(new GetAllUsersAction({}));
    this.store$.dispatch(new GetTeamsAction({}));
    this.userTeamList$ = this.scheduleService.getUserAndTeamList();
    this.toggleOption = this.router.url.includes('auto') ? this.toggleOptions.Auto : this.toggleOptions.Calendar;
    this.weekMonthOption = this.router.url.includes('month') ? this.weekMonthOptions.Month : this.weekMonthOptions.Week;
    this.subscriptions.add(this.subscribeToRouterEvents());
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.activeUser && this.activeUser) {
      this.initializeQueryParams();
    }
  }

  subscribeToRouterEvents(): Subscription {
    return this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.toggleOption = event.urlAfterRedirects.includes('auto')
          ? this.toggleOptions.Auto
          : this.toggleOptions.Calendar;
        this.weekMonthOption = event.urlAfterRedirects.includes('month')
          ? this.weekMonthOptions.Month
          : this.weekMonthOptions.Week;
        this.cdRef.detectChanges();
      }
    });
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  initializeQueryParams(): void {
    this.queryParams$.pipe(take(1)).subscribe(queryParams => {
      if (!queryParams) {
        this.queryParams = {
          startDate: moment().tz(this.timeZone).format(),
          teamSkeds: false,
          userId: this.activeUser._id,
          teamId: null,
        };
      } else {
        this.queryParams = queryParams;
      }
      this.updateQueryParams();
    });
  }

  displayStartDate(): string {
    if (this.weekMonthOption === this.weekMonthOptions.Week) {
      const endOfWeek = moment(this.startDate).endOf('week').add(1, 'day').format('MMM D');
      return moment(this.startDate).startOf('week').add(1, 'day').format(`MMM D [- ${endOfWeek}]`);
    } else if (this.weekMonthOption === this.weekMonthOptions.Month) {
      return moment(this.startDate).format('MMMM');
    }
  }

  subtract(): void {
    if (this.weekMonthOption === this.weekMonthOptions.Week) {
      this.startDate = moment(this.startDate).subtract(7, 'days').format();
    } else if (this.weekMonthOption === this.weekMonthOptions.Month) {
      this.startDate = moment(this.startDate).subtract(1, 'months').format();
    }
    this.updateQueryParams();
  }

  add(): void {
    if (this.weekMonthOption === this.weekMonthOptions.Week) {
      this.startDate = moment(this.startDate).add(7, 'days').format();
    } else if (this.weekMonthOption === this.weekMonthOptions.Month) {
      this.startDate = moment(this.startDate).add(1, 'months').format();
    }
    this.updateQueryParams();
  }

  changeOption(option: ToggleOptions): void {
    this.toggleOption = option;
    if (option === this.toggleOptions.Auto) {
      this.router.navigate(['/schedule/auto']);
    } else if (option === this.toggleOptions.Calendar) {
      this.router.navigate([`/schedule/${this.weekMonthOption}`]);
    }
  }

  changeWeekMonth(option: WeekMonthOptions): void {
    this.weekMonthOption = option;
    this.router.navigate([`/schedule/${option}`]);
  }

  changeUserTeam(menuOption: UserTeam): void {
    this.userTeamOption = menuOption;
    this.updateQueryParams();
  }

  selectUser(menuOption: UserTeam) {
    this.queryParams = {...this.queryParams, userId: menuOption.id, teamId: '', teamSkeds: false};
    this.updateQueryParams();
  }

  selectTeam(menuOption: UserTeam) {
    this.queryParams = {...this.queryParams, teamId: menuOption.id, userId: '', teamSkeds: true};
    this.updateQueryParams();
  }

  updateQueryParams(): void {
    this.store$.dispatch(new UpdateScheduleQueryParams({params: this.queryParams}));
  }
}
