import {Action} from '@ngrx/store';
import {SkillModel} from '../../model/skill';
import {HttpErrorResponse} from '@angular/common/http';

export enum SkillsActionType {
  ADD_EMPTY_SKILL = '[Skills] Add Empty Skill',

  FETCH_SKILLS = '[Skills] Fetch Skills',
  FETCH_SKILLS_SUCCESS = '[Skills] Fetch Skills :: Success',

  GET_SINGLE_SKILL = '[Skills] Get Single Skill',
  GET_SINGLE_SKILL_SUCCESS = '[Skills] Get Single Skill :: Success',

  REMOVE_SKILL = '[Skills] Remove Skill',

  RESET_SKILL_DETAILS = '[Skills] Reset Skill Details',

  SAVE_CURRENT_SKILL = '[Skills] Save Current Skill',
}

export class AddEmptySkillAction implements Action {
  public readonly type = SkillsActionType.ADD_EMPTY_SKILL;
}

export class FetchSkillsAction implements Action {
  public readonly type = SkillsActionType.FETCH_SKILLS;

  constructor(
    public payload: {search?: string; onSuccess?: (skills: SkillModel[]) => void; onFailure?: (error: Error) => void}
  ) {}
}

export class FetchSkillsSuccessAction implements Action {
  public readonly type = SkillsActionType.FETCH_SKILLS_SUCCESS;

  constructor(public payload: {skills: SkillModel[]}) {}
}

export class GetSingleSkillAction implements Action {
  public readonly type = SkillsActionType.GET_SINGLE_SKILL;

  constructor(
    public payload: {id: string; onSuccess: (skill: SkillModel) => void; onFailure: (error: Error) => void}
  ) {}
}

export class GetSingleSkillSuccessAction implements Action {
  public readonly type = SkillsActionType.GET_SINGLE_SKILL_SUCCESS;

  constructor(public payload: {skill: SkillModel}) {}
}

export class RemoveSkillAction implements Action {
  public readonly type = SkillsActionType.REMOVE_SKILL;

  constructor(public payload: {id: string; onSuccess: () => void; onFailure: (error: HttpErrorResponse) => void}) {}
}

export class ResetSkillDetailsAction implements Action {
  public readonly type = SkillsActionType.RESET_SKILL_DETAILS;
}

export class SaveCurrentSkillAction implements Action {
  public readonly type = SkillsActionType.SAVE_CURRENT_SKILL;

  constructor(public payload: {onSuccess: (skill: SkillModel) => void; onFailure: (error: Error) => void}) {}
}

export type SkillsAction =
  | AddEmptySkillAction
  | FetchSkillsAction
  | FetchSkillsSuccessAction
  | GetSingleSkillAction
  | GetSingleSkillSuccessAction
  | RemoveSkillAction
  | ResetSkillDetailsAction
  | SaveCurrentSkillAction;
