import {ChangeDetectionStrategy, Component, Input, OnInit} from '@angular/core';
import {Observable} from 'rxjs';
import {MobileService} from 'src/app/core/page/mobile.service';

@Component({
  selector: 'oph-chip',
  templateUrl: './oph-chip.component.html',
  styleUrls: ['./oph-chip.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OphChipComponent implements OnInit {
  @Input() color: string;
  @Input() text: string;
  @Input() maxWidth: number;

  phone$: Observable<boolean>;

  constructor(private mobileService: MobileService) {}

  ngOnInit() {
    this.phone$ = this.mobileService.observePhone();
  }
}
