import {ChangeDetectionStrategy, Component, OnInit, ViewChild} from '@angular/core';
import {Store, select} from '@ngrx/store';
import {map, Observable} from 'rxjs';
import {User} from 'src/app/core/model/user';
import {selectActiveUser, selectActiveUserRole} from 'src/app/core/store/active-user/active-user.selector';
import {UpdateSelectedSkedTypeAction} from 'src/app/core/store/current-sked/current-sked.action';
import {
  selectCurrentSkedHeaderLoading,
  selectSelectedSkedType,
} from 'src/app/core/store/current-sked/current-sked.selector';
import {SkedType} from 'src/app/core/store/current-sked/current-sked.state';
import {selectTeamsMap} from 'src/app/core/store/teams/teams.selector';
import {selectUsersMap} from 'src/app/core/store/users/users.selector';
import {UserTeam} from 'src/app/schedule/shared/scheduler-toolbar/scheduler-toolbar.component';
import {Team} from 'src/app/teams/shared/model/team';
import {ScheduleService} from '../../services/schedule.service';
import {MatMenuTrigger} from '@angular/material/menu';

@Component({
  selector: 'current-sked-selector',
  templateUrl: './current-sked-selector.component.html',
  styleUrls: ['./current-sked-selector.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CurrentSkedSelectorComponent implements OnInit {
  @ViewChild('skedMenu', {static: false}) menuTrigger: MatMenuTrigger;

  selectedSkedType$: Observable<SkedType>;
  activeUser$: Observable<User> = this.store$.pipe(select(selectActiveUser));
  usersMap$: Observable<Record<string, User>> = this.store$.pipe(select(selectUsersMap));
  teamsMap$: Observable<Record<string, Team>> = this.store$.pipe(select(selectTeamsMap));
  userTeamList$: Observable<{users: UserTeam[]; teams: UserTeam[]}>;
  userRole$: Observable<string> = this.store$.pipe(select(selectActiveUserRole)).pipe(map(role => role?.toLowerCase()));
  headerLoading$: Observable<boolean> = this.store$.pipe(select(selectCurrentSkedHeaderLoading));
  isAdmin$: Observable<boolean>;

  scrollToSelected = false;

  constructor(
    private store$: Store,
    private scheduleService: ScheduleService
  ) {}

  ngOnInit() {
    this.selectedSkedType$ = this.store$.pipe(select(selectSelectedSkedType));
    this.userTeamList$ = this.scheduleService.getUserAndTeamList();
    this.isAdmin$ = this.userRole$.pipe(map(role => role === 'admin'));
  }

  onSelect(selectedSkedType: SkedType, scrollToSelected?: boolean): void {
    this.scrollToSelected = scrollToSelected;
    this.store$.dispatch(new UpdateSelectedSkedTypeAction({selectedSkedType}));
  }

  onMenuOpened(selectedSkedType: SkedType): void {
    if (selectedSkedType?.id && this.scrollToSelected) {
      const selectedElement = document.getElementById(selectedSkedType?.id);
      if (selectedElement) {
        selectedElement.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
          inline: 'nearest',
        });
      }
    }
  }
}
