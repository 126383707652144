import {createSelector} from '@ngrx/store';
import {getParamsKey} from 'src/app/reporting-new/shared/get-params-key';
import {AppState} from '../app-state';

export const selectReportingState = (state: AppState) => state.reporting;

export const selectReportingTrackersParams = createSelector(
  selectReportingState,
  state => state.reportingTrackersParams
);

export const selectReportingTrackers = createSelector(
  selectReportingTrackersParams,
  selectReportingState,
  (params, state) => {
    if (params) {
      return state.trackersReportMap[getParamsKey(params)];
    }
    return null;
  }
);

export const selectRecentlyUpdatedAttributes = createSelector(
  selectReportingState,
  state => state.reportingObjectEntries
);
