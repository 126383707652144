import {TeamsAction, TeamsActionType} from './teams.action';
import {initialTeamsState, TeamsState} from './teams.state';

export function teamsReducer(state: TeamsState = initialTeamsState, action: TeamsAction): TeamsState {
  switch (action.type) {
    case TeamsActionType.GET_SUCCESS:
      return {...state, teams: action.payload.teams};
    case TeamsActionType.CLEAR:
      return {
        teams: [],
      };
    default:
      return state;
  }
}
