import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output} from '@angular/core';
import {AbstractControl, FormGroup} from '@angular/forms';
import {BehaviorSubject} from 'rxjs';

@Component({
  selector: 'twd-repeat',
  templateUrl: './twd-repeat.component.html',
  styleUrls: ['./twd-repeat.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TwdRepeatComponent {
  @Input() form: FormGroup;

  @Output() hideHeaderAndFooter = new EventEmitter<boolean>();

  showCalendar$ = new BehaviorSubject<boolean>(false);
  disabledDays$ = new BehaviorSubject<number[]>([]);

  buttons = [
    {display: 'Does not repeat', value: 'doNotRepeat'},
    {display: 'Repeats', value: 'repeats'},
  ];
  numberOptionsArr = new Array(10);
  repeatTypesArr = ['week', 'day'];
  previousDate: string;
  previousEndsType: string;

  onButton(value: string) {
    let autoReset;
    // Type effects the behavior of the completion and expiration date component
    let type;
    if (value === 'repeats') {
      autoReset = false;
      type = 'onRepeat';
    } else {
      autoReset = this.autoResetControl.value;
      type = 'endOfProject';
    }
    this.form.patchValue({
      schedule: {scheduleType: value, targetCompletionType: type, expirationType: type},
      autoReset,
    });
  }

  onNumber(value: number) {
    this.repeatEveryControl.setValue(value);
  }

  onType(value: string) {
    this.repeatTypeControl.setValue(value);
  }

  onRadioButton(value: string) {
    this.endsTypeControl.setValue(value);
  }

  onCalendarOpen() {
    this.previousDate = this.endsDateControl.value;
    this.previousEndsType = this.endsTypeControl.value;
    this.endsTypeControl.setValue('date');
    this.showCalendar$.next(true);
    this.hideHeaderAndFooter.emit(true);
  }

  onDateChange(date: string) {
    this.scheduleControls.patchValue({endsType: 'date', endsDate: date});
  }

  onCalendarButton(cancel: boolean) {
    if (cancel) {
      this.scheduleControls.patchValue({endsDate: this.previousDate, endsType: this.previousEndsType});
    }
    this.showCalendar$.next(false);
    this.hideHeaderAndFooter.emit(false);
  }

  onClear() {
    this.endsDateControl.setValue('never');
  }

  onOccurrencesBlur(value: number) {
    if (value < 1) {
      this.endsOccurrencesControl.setValue(1);
    }
  }

  get autoResetControl(): AbstractControl {
    return this.form.get('autoReset');
  }

  get scheduleControls(): AbstractControl {
    return this.form.get('schedule');
  }

  get scheduleTypeControl(): AbstractControl {
    return this.scheduleControls.get('scheduleType');
  }

  get repeatEveryControl(): AbstractControl {
    return this.scheduleControls.get('repeatEvery');
  }

  get repeatTypeControl(): AbstractControl {
    return this.scheduleControls.get('repeatType');
  }

  get repeatDaysControl(): AbstractControl {
    return this.scheduleControls.get('repeatDays');
  }

  get endsTypeControl(): AbstractControl {
    return this.scheduleControls.get('endsType');
  }

  get endsDateControl(): AbstractControl {
    return this.scheduleControls.get('endsDate');
  }

  get endsOccurrencesControl(): AbstractControl {
    return this.scheduleControls.get('endsOccurrences');
  }
}
