<span class="section-title">PROJECTS</span>

<oph-autocomplete
  placeholder="Add Project"
  [options]="formattedProjects"
  (optionChange)="onProject($event)"
  preventSelectedOption="true"
>
</oph-autocomplete>

@if (projectsMap) {
  <div class="project-list">
    @for (project of projectIdsFormArray?.value || []; track project; let i = $index) {
      @if (projectsMap[project]) {
        <div class="project">
          <div class="project-left-container">
            <div class="project-color" [style.background]="projectsMap[project]?.color"></div>
            <span class="project-title">{{ projectsMap[project]?.label || 'Not found' }}</span>
          </div>
          <button class="x-button" (click)="onRemoveProject(i)">
            <oph-icon name="x-red"></oph-icon>
          </button>
        </div>
      }
    }
  </div>
}
