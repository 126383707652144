import {Action as ActionModel} from '@ngrx/store';
import {SeasonModel, SkedTemplateModel, WeekModel, WeekSkedModel, WeekSkedTemplateModel} from './scheduler-dummy-data';
import {TeamScheduleSearchParams} from '../../../team-schedule/shared/model/team-schedule-search-params';
import {TeamSchedule} from 'src/app/team-schedule/shared/model/team-schedule';

export enum SchedulerActionType {
  COPY_WEEK_TO_NEXT_EMPTY_SLOT = '[Scheduler] Copy Week To Next Empty Slot',

  CREATE_SKED_TEMPLATE = '[Scheduler] Create Sked Template',
  CREATE_SKED_TEMPLATE_SUCCESS = '[Scheduler] Create Sked Template :: Success',

  CREATE_WEEK = '[Scheduler] Create Week',
  CREATE_WEEK_SUCCESS = '[Scheduler] Create Week :: Success',

  DUPLICATE_WEEK = '[Scheduler] Duplicate Week',

  FETCH_WEEKS = '[Scheduler] Fetch Weeks',
  FETCH_WEEKS_SUCCESS = '[Scheduler] Fetch Weeks :: Success',

  FETCH_CURRENT_SEASON = '[Scheduler] Fetch Current Season',
  FETCH_CURRENT_SEASON_SUCCESS = '[Scheduler] Fetch Current Season :: Success',

  FETCH_CURRENT_WEEK = '[Scheduler] Init Current Week',
  FETCH_CURRENT_WEEK_SUCCESS = '[Scheduler] Init Current Week :: Success',

  FETCH_SKED_TEMPLATES = '[Scheduler] Fetch Sked Templates',
  FETCH_SKED_TEMPLATES_SUCCESS = '[Scheduler] Fetch Sked Templates :: Success',

  REMOVE_SKED_TEMPLATE = '[Scheduler] Remove Sked Template',

  REMOVE_SKED_FROM_WEEK = '[Scheduler] Remove Sked from Week',

  REMOVE_WEEK = '[Scheduler] Remove Week',

  RESET_SEASON = '[Scheduler] Reset Season',

  RESET_WEEK = '[Scheduler] Reset Week',

  SAVE_CURRENT_WEEK = '[Scheduler] Save Current Week',
  SAVE_CURRENT_WEEK_SUCCESS = '[Scheduler] Save Current Week :: Success',

  SET_SAVE_IN_PROGRESS = '[Scheduler] Set Save In progress',

  SET_HAS_UNSAVED_CHANGES = '[Scheduler] Set Has Unsaved Changes',

  UPDATE_CURRENT_WEEK_SKED_BY_ID = '[Scheduler] Update Current Week Sked By Id',

  UPDATE_CURRENT_WEEK_SKEDS = '[Scheduler] Update Current Week Skeds',
  UPDATE_CURRENT_WEEK_SKEDS_SUCCESS = '[Scheduler] Update Current Week Skeds :: Success',

  UPDATE_SKED_TEMPLATE = '[Scheduler] Update Sked Template',

  UPDATE_WEEK = '[Scheduler] Update Week',
  UPDATE_WEEK_SUCCESS = '[Scheduler] Update Week :: Success',

  SET_TEAM_SKEDS = '[Scheduler] Team Skeds',
  SET_TEAM_SKEDS_SUCCESS = '[Scheduler] Team Skeds :: Success',

  GET_TEAM_SCHEDULE = '[Scheduler] Get Team Schedule',
  GET_TEAM_SCHEDULE_SUCCESS = '[Scheduler] Get Team Schedule :: Success',

  CLEAR = '[Scheduler] Clear',
}

export class CopyToNextEmptyWeekAction implements ActionModel {
  public readonly type = SchedulerActionType.COPY_WEEK_TO_NEXT_EMPTY_SLOT;

  constructor(public week: WeekModel) {}
}

export class CreateSkedTemplateAction implements ActionModel {
  public readonly type = SchedulerActionType.CREATE_SKED_TEMPLATE;

  public constructor(public payload: {sked: WeekSkedTemplateModel; onSuccess: () => void}) {}
}

export class CreateSkedTemplateSuccessAction implements ActionModel {
  public readonly type = SchedulerActionType.CREATE_SKED_TEMPLATE_SUCCESS;
}

export class CreateWeekAction implements ActionModel {
  public readonly type = SchedulerActionType.CREATE_WEEK;

  public constructor(public payload: {onSuccess: (week: WeekModel) => void; startDate: string; teamSkeds: boolean}) {}
}

export class CreateWeekSuccessAction implements ActionModel {
  public readonly type = SchedulerActionType.CREATE_WEEK_SUCCESS;

  public constructor(public payload: {week: WeekModel}) {}
}

export class FetchCurrentWeekAction implements ActionModel {
  public readonly type = SchedulerActionType.FETCH_CURRENT_WEEK;

  constructor(public payload: {weekId: string; onSuccess?: (week: WeekModel) => void; onError?: () => void}) {}
}

export class FetchCurrentWeekSuccessAction implements ActionModel {
  public readonly type = SchedulerActionType.FETCH_CURRENT_WEEK_SUCCESS;

  constructor(public week: WeekModel) {}
}

export class FetchSkedTemplatesAction implements ActionModel {
  public readonly type = SchedulerActionType.FETCH_SKED_TEMPLATES;

  constructor(public payload: {search?: string; onSuccess?: (week: WeekModel) => void}) {}
}

export class FetchSkedTemplatesSuccessAction implements ActionModel {
  public readonly type = SchedulerActionType.FETCH_SKED_TEMPLATES_SUCCESS;

  constructor(public skedTemplates: SkedTemplateModel[]) {}
}

export class DuplicateWeekAction implements ActionModel {
  public readonly type = SchedulerActionType.DUPLICATE_WEEK;

  constructor(public payload: {weekId: string; onSuccess: (week: WeekModel) => void}) {}
}

export class FetchCurrentSeasonAction implements ActionModel {
  public readonly type = SchedulerActionType.FETCH_CURRENT_SEASON;

  constructor(public payload: {onSuccess: (season: SeasonModel) => void}) {}
}

export class FetchCurrentSeasonSuccessAction implements ActionModel {
  public readonly type = SchedulerActionType.FETCH_CURRENT_SEASON_SUCCESS;

  constructor(public payload: {season: SeasonModel}) {}
}

export class FetchWeeksAction implements ActionModel {
  public readonly type = SchedulerActionType.FETCH_WEEKS;

  constructor(
    public payload: {
      filter?: {
        startDate?: string;
        numberOfWeeks?: number;
        teamSkeds?: boolean;
      };
      onSuccess?: (weeks: WeekModel[]) => void;
    }
  ) {}
}

export class FetchWeekSuccessAction implements ActionModel {
  public readonly type = SchedulerActionType.FETCH_WEEKS_SUCCESS;

  constructor(public payload: {seasonWeeks: WeekModel[]}) {}
}

export class RemoveSkedFromWeekAction implements ActionModel {
  public readonly type = SchedulerActionType.REMOVE_SKED_FROM_WEEK;

  constructor(public payload: {skedId: string; onSuccess: () => void}) {}
}

export class RemoveSkedTemplateAction implements ActionModel {
  public readonly type = SchedulerActionType.REMOVE_SKED_TEMPLATE;

  constructor(public payload: {id: string; onSuccess: () => void}) {}
}

export class RemoveWeekAction implements ActionModel {
  public readonly type = SchedulerActionType.REMOVE_WEEK;

  constructor(public payload: {id: string; onSuccess: () => void}) {}
}

export class ResetSeasonViewAction implements ActionModel {
  public readonly type = SchedulerActionType.RESET_SEASON;
}

export class ResetWeekViewAction implements ActionModel {
  public readonly type = SchedulerActionType.RESET_WEEK;
}

export class SaveCurrentWeekAction implements ActionModel {
  public readonly type = SchedulerActionType.SAVE_CURRENT_WEEK;

  constructor(public payload: {onSuccess?: () => void}) {}
}

export class SaveCurrentWeekSuccessAction implements ActionModel {
  public readonly type = SchedulerActionType.SAVE_CURRENT_WEEK_SUCCESS;

  constructor(public week: WeekModel) {}
}

export class SetSaveInProgressAction implements ActionModel {
  public readonly type = SchedulerActionType.SET_SAVE_IN_PROGRESS;

  constructor(public state: boolean) {}
}

export class SetHasUnsavedChangesAction implements ActionModel {
  public readonly type = SchedulerActionType.SET_HAS_UNSAVED_CHANGES;

  constructor(public state: boolean) {}
}

export class UpdateCurrentWeekSkedByIdAction implements ActionModel {
  public readonly type = SchedulerActionType.UPDATE_CURRENT_WEEK_SKED_BY_ID;

  constructor(public payload: {sked: WeekSkedModel; onSuccess: () => void}) {}
}

export class UpdateCurrentWeekSkedsAction implements ActionModel {
  public readonly type = SchedulerActionType.UPDATE_CURRENT_WEEK_SKEDS;

  constructor(public payload: {skeds: WeekSkedModel[]; onSuccess: () => void}) {}
}

export class UpdateCurrentWeekSkedsSuccessAction implements ActionModel {
  public readonly type = SchedulerActionType.UPDATE_CURRENT_WEEK_SKEDS_SUCCESS;

  constructor(public week: WeekModel) {}
}

export class UpdateWeekAction implements ActionModel {
  public readonly type = SchedulerActionType.UPDATE_WEEK;

  constructor(public payload: {week: WeekModel; onSuccess: () => void}) {}
}

export class UpdateWeekSuccessAction implements ActionModel {
  public readonly type = SchedulerActionType.UPDATE_WEEK_SUCCESS;

  constructor(public week: WeekModel) {}
}

export class UpdateSkedTemplateAction implements ActionModel {
  public readonly type = SchedulerActionType.UPDATE_SKED_TEMPLATE;

  constructor(public payload: {sked: SkedTemplateModel; onSuccess: () => void}) {}
}

export class SetTeamSkedsAction implements ActionModel {
  public readonly type = SchedulerActionType.SET_TEAM_SKEDS;

  constructor(public state: boolean) {}
}

export class GetTeamScheduleAction implements ActionModel {
  public readonly type = SchedulerActionType.GET_TEAM_SCHEDULE;

  constructor(
    public payload: {
      teamId: string;
      params: TeamScheduleSearchParams;
      onSuccess?: (week: WeekModel) => void;
      onFailure?: (err: string) => void;
    }
  ) {}
}

export class GetTeamScheduleSuccessAction implements ActionModel {
  public readonly type = SchedulerActionType.GET_TEAM_SCHEDULE_SUCCESS;

  constructor(public teamSchedule: TeamSchedule) {}
}

export class ClearSchedulerAction implements ActionModel {
  public readonly type = SchedulerActionType.CLEAR;
}

export type SchedulerAction =
  | CopyToNextEmptyWeekAction
  | CreateSkedTemplateAction
  | CreateSkedTemplateSuccessAction
  | CreateWeekAction
  | CreateWeekSuccessAction
  | DuplicateWeekAction
  | FetchCurrentWeekAction
  | FetchCurrentWeekSuccessAction
  | FetchSkedTemplatesAction
  | FetchSkedTemplatesSuccessAction
  | FetchCurrentSeasonAction
  | FetchCurrentSeasonSuccessAction
  | FetchWeeksAction
  | FetchWeekSuccessAction
  | RemoveSkedFromWeekAction
  | RemoveSkedTemplateAction
  | RemoveWeekAction
  | ResetSeasonViewAction
  | ResetWeekViewAction
  | SaveCurrentWeekAction
  | SaveCurrentWeekSuccessAction
  | SetHasUnsavedChangesAction
  | SetSaveInProgressAction
  | UpdateCurrentWeekSkedByIdAction
  | UpdateCurrentWeekSkedsAction
  | UpdateCurrentWeekSkedsSuccessAction
  | UpdateSkedTemplateAction
  | UpdateWeekAction
  | UpdateWeekSuccessAction
  | SetTeamSkedsAction
  | GetTeamScheduleAction
  | GetTeamScheduleSuccessAction
  | ClearSchedulerAction;
