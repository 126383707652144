@if ({trackersMap: trackersMap$ | async, metricsDisplay: metricsDisplay$ | async}; as state) {
  @if (project) {
    <div cdkDropListGroup class="main-container">
      <div class="left-container">
        <oph-new-button
          class="new-step-button"
          (buttonClick)="onOpenStepDialog(null, null)"
          [buttonStyle]="{width: '153px', height: '34px', borderRadius: '6px', background: 'var(--light)'}"
          >New Step</oph-new-button
        >
        <!-- --------------STEPS---------------- -->
        <div class="steps-wrapper">
          @for (step of project.steps; track $index; let stepIndex = $index) {
            <div class="step-container" [class.inactive-step]="step.status !== 'active'">
              @if (state.trackersMap) {
                <div
                  [class.show]="step.previousStepRequired"
                  class="connector-container"
                  [matTooltip]="
                    (project.steps[stepIndex - 1] | isTrackerComplete: state.trackersMap : 'completionTracker')
                      ? null
                      : 'The preceding step has no completion rule.'
                  "
                  matTooltipPosition="right"
                >
                  <img src="assets/img/icons/projects/connector.svg" />
                  @if (!(project.steps[stepIndex - 1] | isTrackerComplete: state.trackersMap : 'completionTracker')) {
                    <img src="assets/img/icons/projects/alert-triangle.svg" class="alert-triangle" />
                  }
                </div>
              }

              <div
                class="drop-container"
                cdkDropList
                [cdkDropListData]="{tasks: step.tasks, isTemplate: false, stepIndex}"
                (cdkDropListDropped)="taskDrop($event)"
              >
                <div class="step-header">
                  @if (step.status === 'complete') {
                    <oph-icon name="check-green-background" [size]="30"></oph-icon>
                  } @else {
                    <div class="uncomplete-step-count">{{ stepIndex + 1 }}</div>
                  }
                  {{ step.name }}
                  <div class="step-header-right-container">
                    <oph-chip-button (buttonClick)="onCreateTaskForProject(stepIndex)">New Task</oph-chip-button>
                    <button class="edit-step-button" (click)="onStepEdit(step, stepIndex)">
                      <mat-icon svgIcon="pencil-outline"></mat-icon>
                    </button>
                  </div>
                </div>

                @if (state.metricsDisplay && state.trackersMap && step.activationTracker) {
                  @if (state.metricsDisplay?.[stepIndex]?.activation?.name && stepIndex !== 0) {
                    <div
                      class="tracker-display-container activation"
                      [class.task-list-empty-margin]="!step.tasks.length"
                    >
                      <oph-icon name="start-green" size="19" class="start-green-icon"></oph-icon>
                      <span class="main-span">
                        Activate when
                        <span class="bold-metric">{{ state.metricsDisplay[stepIndex]?.activation?.name }}</span>
                        {{ step.activationTracker.operator | operatorDisplay }}
                        <span class="bold-metric">{{ state.metricsDisplay[stepIndex]?.activation?.value }}</span>
                      </span>
                    </div>
                  }
                }

                <div class="task-list">
                  @for (task of step.tasks; track task; let i = $index) {
                    <projects-edit-task
                      #taskContainer
                      [task]="task"
                      [requirementsMap]="requirementsMap$ | async"
                      [locationsMap]="locationsMap$ | async"
                      [loading]="(loadingStepIndex$ | async) === stepIndex && (loadingTaskIndex$ | async) === i"
                      (click)="openManualProjectTaskModal(stepIndex, step._id, i)"
                    ></projects-edit-task>
                  }
                </div>
              </div>
              <oph-add-button (buttonClick)="onCreateTaskForProject(stepIndex)">New task</oph-add-button>
              @if (state.metricsDisplay && state.trackersMap) {
                @if (state.metricsDisplay?.[stepIndex]?.completion?.name) {
                  <div class="tracker-display-container">
                    <oph-icon name="check-square-green" size="19"></oph-icon>
                    <span class="main-span"
                      >Complete when
                      <span class="bold-metric">{{ state.metricsDisplay[stepIndex]?.completion?.name }}</span>
                      {{ step.completionTracker.operator | operatorDisplay }}
                      <span class="bold-metric">{{ state.metricsDisplay[stepIndex]?.completion?.value }}</span>
                    </span>
                  </div>
                }
              }
            </div>
          }
        </div>
      </div>

      <div class="tabs-container">
        <oph-button-group
          [options]="tabOptions"
          [selectedOptionName]="selectedTab$ | async"
          (optionClick)="onTabChange($event)"
        ></oph-button-group>
        <oph-search-input
          [control]="search"
          [inputStyle]="{height: '42px'}"
          placeholder="Search Task Templates"
        ></oph-search-input>
        <oph-new-button (buttonClick)="openTaskTemplateDialog()">New Task Template</oph-new-button>
        <!-- --------------TASK TEMPLATE LIST---------------- -->
        <div
          cdkDropList
          [cdkDropListData]="{taskTemplates, isTemplate: true, stepIndex}"
          cdkDropListSortingDisabled
          class="template-list"
        >
          @for (taskTemplate of taskTemplates; track $index) {
            <div cdkDrag [cdkDragData]="taskTemplate" class="task-item">
              <projects-edit-task
                [template]="true"
                [task]="taskTemplate"
                [requirementsMap]="requirementsMap$ | async"
                [locationsMap]="locationsMap$ | async"
                (click)="openTaskTemplateDialog(taskTemplate)"
              ></projects-edit-task>
            </div>
          }
        </div>
      </div>
    </div>
  }
}
