<div class="row">
  <oph-input-orange
    [control]="metricForm.get('name')"
    [inputStyle]="{height: '36px', color: 'var(--brown-med)'}"
    [orangeBorder]="true"
  ></oph-input-orange>
</div>

<div class="row">
  <tracker-detail-metric-select
    [buttonText]="typeControl.value | trackerMetricTypeDisplay"
    [options]="typeOptions"
    [disabled]="editing"
    (optionChange)="onTypeChange($event)"
    class="mw-200"
  ></tracker-detail-metric-select>
</div>

<div class="row">
  <div class="label-item">
    <label>CURRENT VALUE</label>
    <div class="current-value-container">
      {{
        typeControl.value === trackerMetricTypes.Boolean
          ? (metricForm.get('value').value | numberToBoolean)
          : metricForm.get('value').value
      }}
    </div>
  </div>

  <oph-checkbox
    text="Editable"
    textOrientation="right"
    [control]="metricForm.get('updateable')"
    class="oph-checkbox-margin"
  ></oph-checkbox>
</div>

<div class="row">
  @if (typeControl.value !== trackerMetricTypes.Select) {
    <div class="label-item mr-10">
      <label>DEFAULT VALUE</label>
      <tracker-metric-value-update
        [metric]="metricForm.value"
        [control]="metricForm.get('defaultValue')"
        [inputStyle]="{
          height: '26px',
          'border-radius': '6px',
          'max-width': '142px',
          'font-size': '14px',
          'font-weight': '400',
          color: 'var(--brown-med)'
        }"
        [showNumericUpdateButton]="false"
        [options]="optionsFormArray?.value | trackerSelectOptions"
      ></tracker-metric-value-update>
    </div>
  }

  @if (typeControl.value === trackerMetricTypes.Numeric) {
    <div class="label-item">
      <label>SUFFIX</label>
      <oph-input-orange
        [control]="metricForm.get('uom')"
        [inputStyle]="{
          height: '26px',
          'border-radius': '6px',
          'max-width': '142px',
          'font-size': '14px',
          'font-weight': '400',
          color: 'var(--brown-med)'
        }"
        [orangeBorder]="false"
      ></oph-input-orange>
    </div>
  }
</div>

@if (typeControl.value === trackerMetricTypes.Select) {
  <div class="label-item" [class.long-label-item]="typeControl.value === trackerMetricTypes.Select">
    <label>LIST OPTIONS</label>
    <div class="list-options-container">
      <span class="select-default-text">DEFAULT</span>
      @for (formGroup of optionsFormArray?.controls; track formGroup; let i = $index) {
        <div class="list-option-container">
          <oph-input-orange
            [control]="formGroup.controls.value"
            [inputStyle]="{
              height: '26px',
              'border-radius': '6px',
              'max-width': '300px',
              'font-size': '14px',
              'font-weight': '400',
              color: 'var(--brown-med)'
            }"
          ></oph-input-orange>
          <oph-checkbox
            (valueChange)="onSelectDefaultValueChange(i, $event)"
            [checked]="formGroup.value.default"
          ></oph-checkbox>
          <oph-x-button
            [class.hidden-x-button]="optionsFormArray?.length === 1"
            (click)="onRemoveOption(i)"
          ></oph-x-button>
        </div>
      }
      <oph-button
        color="orange"
        size="small"
        [style]="{padding: '0 12px', 'font-size': '14px', 'border-radius': '10px'}"
        (buttonClick)="onNewOption()"
        class="new-option-button"
      >
        <mat-icon svgIcon="plus" class="plus-yellow"></mat-icon>
        New Option
      </oph-button>
    </div>
  </div>
}

<!-- This might get added back in -->
<!-- @if (typeControl.value === 'numeric') {
  <div class="row">
    <div class="label-item mr-10" [class.long-label-item]="typeControl.value === 'select'">
      <label>MIN</label>
      <oph-input-orange
        [control]="metricForm.get('min')"
        [style]="{
          height: '26px',
          'border-radius': '6px',
          'max-width': '142px',
          'font-size': '14px',
          'font-weight': '400',
          color: 'var(--brown-med)'
        }"
        type="number"
      ></oph-input-orange>
    </div>
    <div class="label-item" [class.long-label-item]="typeControl.value === 'select'">
      <label>MAX</label>
      <oph-input-orange
        [control]="metricForm.get('max')"
        [style]="{
          height: '26px',
          'border-radius': '6px',
          'max-width': '142px',
          'font-size': '14px',
          'font-weight': '400',
          color: 'var(--brown-med)'
        }"
        type="number"
      ></oph-input-orange>
    </div>
  </div>
} -->
