import {ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges} from '@angular/core';
import {FormArray, FormBuilder, FormControl, FormGroup} from '@angular/forms';
import {Project} from 'src/app/core/model/project';
import {
  OphAutocompleteComponent,
  OphAutocompleteOption,
} from 'src/app/shared/design/oph-autocomplete/oph-autocomplete.component';
import {OphIconModule} from '../../../shared/design/oph-icon/oph-icon.module';
import {User} from 'src/app/core/model/user';

@Component({
  selector: 'tracker-detail-project-selection',
  standalone: true,
  imports: [OphAutocompleteComponent, OphIconModule],
  templateUrl: './tracker-detail-project-selection.component.html',
  styleUrl: './tracker-detail-project-selection.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TrackerDetailProjectSelectionComponent implements OnChanges {
  @Input() form: FormGroup;
  @Input() projects: Project[];
  @Input() projectsMap: Record<string, Project>;
  @Input() activeUser: User;

  formattedProjects: Partial<Project>[];

  constructor(private fb: FormBuilder) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.projects && this.projects) {
      this.formattedProjects = this.projects.map(project => {
        return {
          name: project.label,
          _id: project._id,
          color: project.color,
        };
      });
    }
  }

  onProject(project: OphAutocompleteOption) {
    if (this.projectIdsFormArray.value.some(projectId => projectId === project._id)) {
      return;
    }
    this.projectIdsFormArray.push(this.createItem(project));
  }

  createItem(project: OphAutocompleteOption): FormControl {
    return this.fb.control(project._id);
  }

  onRemoveProject(index: number) {
    this.projectIdsFormArray.removeAt(index);
  }

  get projectIdsFormArray(): FormArray {
    return this.form.get('projectIds') as FormArray;
  }
}
