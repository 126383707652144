import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'isEvenNumber',
  standalone: true,
})
export class IsEvenNumberPipe implements PipeTransform {
  transform(value: number): boolean {
    return value % 2 === 0;
  }
}
