import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import {OphIconModule} from '../../oph-icon/oph-icon.module';
import {FormControl, ReactiveFormsModule} from '@angular/forms';
import {CommonModule} from '@angular/common';

@Component({
  selector: 'oph-search-input',
  standalone: true,
  imports: [OphIconModule, ReactiveFormsModule, CommonModule],
  templateUrl: './oph-search-input.component.html',
  styleUrl: './oph-search-input.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OphSearchInputComponent implements OnInit, OnChanges {
  @Input() control: FormControl;
  @Input() inputStyle: Object;
  @Input() placeholder: string;
  @Input() value: string;

  @Output() valueChange = new EventEmitter<string>();

  defaultControl = new FormControl('');

  ngOnInit() {
    if (!this.control) {
      this.control = this.defaultControl;
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['value']) {
      this.control.setValue(this.value);
    }
  }
}
