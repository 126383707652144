<div class="left-side-bar default-theme">
  @for (item of filteredMenuItems$ | async; track item; let i = $index) {
    @if (item.url) {
      <navbar-item
        [routerLink]="item.url"
        [icon]="item.icon"
        [display]="item.display"
        class="item"
        [selected]="(selectedIndex$ | async) === i"
      ></navbar-item>
    }
    @if (!item.url) {
      <div class="section-header">{{ item.display }}</div>
    }
  }
</div>
