import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {AbstractControl, FormArray, FormControl, FormGroup} from '@angular/forms';
import {Project} from 'src/app/core/model/project';
import {TasksService} from 'src/app/shared/services/tasks.service';

@Component({
  selector: 'twd-trigger',
  templateUrl: './twd-trigger.component.html',
  styleUrls: ['./twd-trigger.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TwdTriggerComponent {
  @Input() form: FormGroup;
  @Input() project: Project;
  @Input() scheduleControls: FormControl;

  constructor(private taskService: TasksService) {}

  onNoTrigger() {
    this.triggersFormArray.removeAt(0);
  }

  onTrigger() {
    if (this.triggersFormArray.length > 0) {
      return;
    }
    // Type effects the behavior of the completion and expiration date component
    const type = this.scheduleControls.value === 'repeats' ? 'onRepeat' : 'endOfProject';
    this.scheduleControls.patchValue({targetCompletionType: type, expirationType: type});
    this.triggersFormArray.push(this.taskService.getTriggerFormGroup());
  }

  onClearTrigger() {
    this.onNoTrigger();
  }

  get eventControl(): AbstractControl {
    return this.form.get('event');
  }

  get triggersFormArray(): FormArray {
    return this.form.get('triggers') as FormArray;
  }

  get autoResetControl(): AbstractControl {
    return this.form.get('autoReset');
  }

  get scheduleControl(): AbstractControl {
    return this.form.get('schedule');
  }

  get scheduleTypeControl(): AbstractControl {
    return this.scheduleControl.get('scheduleType');
  }
}
