import {Pipe, PipeTransform} from '@angular/core';
import {ProjectStep} from 'src/app/core/model/project';
import {Tracker} from 'src/app/core/model/tracker';

@Pipe({
  name: 'isTrackerComplete',
  standalone: true,
})
export class IsTrackerCompletePipe implements PipeTransform {
  transform(
    step: ProjectStep,
    trackersMap: Record<string, Tracker>,
    type: 'completionTracker' | 'activationTracker'
  ): boolean {
    return (
      !!step?.[type]?.trackerId &&
      trackersMap[step?.[type].trackerId] &&
      !!step?.[type]?.metricId &&
      !!trackersMap[step?.[type].trackerId]?.metrics?.find(m => m._id === step?.[type].metricId) &&
      !!step?.[type]?.operator &&
      !!step?.[type]?.value.toString()
    );
  }
}
