<div class="scheduler-toolbar">
  <div class="toggle-btn">
    <div
      class="option"
      [class.selected-auto]="toggleOption === toggleOptions.Auto"
      (click)="changeOption(toggleOptions.Auto)"
    >
      <span>Auto</span>
    </div>
    <div
      class="option"
      [class.selected]="toggleOption === toggleOptions.Calendar"
      (click)="changeOption(toggleOptions.Calendar)"
    >
      <span>Calendar</span>
    </div>
  </div>
  @if (toggleOption === toggleOptions.Calendar) {
    <div class="toggle-btn">
      @if (toggleOption === toggleOptions.Calendar) {
        <div class="week-month-selector">
          <mat-icon svgIcon="chevron-left" (click)="subtract()"></mat-icon>
          <span class="title">{{ displayStartDate() }}</span>
          <mat-icon svgIcon="chevron-right" (click)="add()"></mat-icon>
        </div>
      }
      <div
        class="option"
        [class.selected]="weekMonthOption === weekMonthOptions.Week"
        (click)="changeWeekMonth(weekMonthOptions.Week)"
      >
        <span>Week</span>
      </div>
      <div
        class="option"
        [class.selected]="weekMonthOption === weekMonthOptions.Month"
        (click)="changeWeekMonth(weekMonthOptions.Month)"
      >
        <span>Month</span>
      </div>
    </div>
  }

  @if ({usersMap: usersMap$ | async, teamsMap: teamsMap$ | async, userTeamList: userTeamList$ | async}; as state) {
    @if (state.usersMap && state.teamsMap && state.userTeamList) {
      <button class="user-select-button" [matMenuTriggerFor]="userTeamMenu">
        @if (state.usersMap?.[queryParams?.userId]) {
          <mat-icon class="user-icon" svgIcon="user"></mat-icon>
          <span>{{ state.usersMap?.[queryParams?.userId]?.username || '(Name not found u)' }}</span>
        }
        @if (state.teamsMap?.[queryParams?.teamId]) {
          <mat-icon class="user-icon" svgIcon="users"></mat-icon>
          <span>{{ state.teamsMap?.[queryParams?.teamId]?.name || '(Name not found t)' }}</span>
        }
        <mat-icon class="chevron-icon" svgIcon="chevron-down"></mat-icon>
      </button>
      <mat-menu #userTeamMenu="matMenu" class="oph-select-menu">
        <div class="oph-schedule-menu-title">Users</div>
        <button
          *ngFor="let menuOption of state.userTeamList?.users"
          (click)="selectUser(menuOption)"
          class="oph-select-menu-item w-full"
        >
          <span>{{ menuOption.label }}</span>
        </button>
        @if (state.userTeamList.teams.length) {
          <div class="oph-schedule-menu-title">Teams</div>
        }
        <button
          *ngFor="let menuOption of state.userTeamList?.teams"
          (click)="selectTeam(menuOption)"
          class="oph-select-menu-item w-full"
        >
          <span>{{ menuOption.label }}</span>
        </button>
      </mat-menu>
    }
  }
</div>
