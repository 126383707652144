import {CdkDrag, CdkDragPlaceholder} from '@angular/cdk/drag-drop';
import {UpperCasePipe} from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatTooltipModule} from '@angular/material/tooltip';
import * as moment from 'moment';
import {SkillModel} from 'src/app/core/model/skill';
import {ProjectTask} from 'src/app/core/model/task';
import {OphIconModule} from 'src/app/shared/design/oph-icon/oph-icon.module';
import {DoesTaskHaveTriggerPipe} from 'src/app/shared/pipes/does-task-have-trigger.pipe';
import {PipesModule} from 'src/app/shared/pipes/pipes.module';

@Component({
  selector: 'projects-edit-task',
  standalone: true,
  imports: [
    OphIconModule,
    PipesModule,
    UpperCasePipe,
    CdkDrag,
    MatTooltipModule,
    CdkDragPlaceholder,
    MatProgressSpinnerModule,
    DoesTaskHaveTriggerPipe,
  ],
  templateUrl: './projects-edit-task.component.html',
  styleUrl: './projects-edit-task.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProjectsEditTaskComponent implements OnChanges {
  @Input() task: ProjectTask;
  @Input() disabled: boolean;
  @Input() requirementsMap: Record<string, SkillModel>;
  @Input() locationsMap: Record<string, Location>;
  @Input() template: boolean;
  @Input() loading: boolean;

  @Output() taskWidthChanged = new EventEmitter<number>();

  isHovered = false;
  priorityColor: string;
  shortenedSkillsArray: string[];
  location: string;
  eventTime: string;

  constructor(public elementRef: ElementRef) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.task && this.task) {
      this.priorityColor = this.getTaskPriorityColor();
      this.shortenedSkillsArray = this.task.skills?.slice(0, 2);
      if (!this.task.event || this.template) {
        return;
      }
      const date = moment(this.task.schedule?.scheduleOnDate || '').format('MMM DD');
      this.eventTime = `${date} ${String(this.task.schedule?.eventStart || '')}`;
    }
  }

  getTaskPriorityColor() {
    switch (this.task.priority) {
      case 1:
        return '#0099ff';
      case 2:
        return '#44bb9e';
      case 3:
        return '#ff9f2d';
      case 4:
        return '#fc5555';
      default:
        return '';
    }
  }

  getNativeElement() {
    return this.elementRef.nativeElement;
  }
}
