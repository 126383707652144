import {LensesAction, LensesActionType} from './lenses.action';
import {LensesState, initialLensesState} from './lenses.state';

export function lensesReducer(state: LensesState = initialLensesState, action: LensesAction): LensesState {
  switch (action.type) {
    case LensesActionType.GET_SUCCESS:
      return {...state, lenses: action.payload.lenses};
    case LensesActionType.GET_SINGLE_SUCCESS:
      return {...state, lens: action.payload.lens};
    case LensesActionType.CLEAR_SINGLE:
      return {...state, lens: null};
    case LensesActionType.GET_USER_LENS_SUCCESS:
      return {...state, userLens: action.payload.userLens, userLensSettingsMap: action.payload.userLensSettingsMap};
    case LensesActionType.GET_SETTINGS_SUCCESS:
      return {...state, settings: action.payload.settings, settingsMap: action.payload.settingsMap};
    case LensesActionType.CLEAR:
      return {
        lenses: [],
        lens: null,
        userLens: null,
        userLensSettingsMap: {},
        settings: [],
        settingsMap: {},
      };
    default:
      return state;
  }
}
