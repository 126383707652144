import {ReportingObjectEntry} from 'src/app/reporting/objects/shared/model/reporting-object-entry';
import {ReportingTrackers, ReportingTrackersParams} from '../../model/reporting';

export interface ReportingState {
  reportingTrackersParams: ReportingTrackersParams;
  trackersReportMap: Record<string, ReportingTrackers>;
  reportingObjectEntries: ReportingObjectEntry[];
}

export const initialReportingState: ReportingState = {
  reportingTrackersParams: null,
  trackersReportMap: {},
  reportingObjectEntries: null,
};
