import {MatDialogConfig} from '@angular/material/dialog';
import {
  Project,
  ProjectActionOption,
  ProjectStep,
  ProjectViewRoutineDetailParams,
  ProjectViewTokenFilterParams,
} from 'src/app/core/model/project';

export const DURATION_OPTIONS = [
  '15',
  '30',
  '45',
  '60',
  '75',
  '90',
  '105',
  '120',
  '135',
  '150',
  '165',
  '180',
  '195',
  '210',
  '225',
  '240',
];

export const OPERATOR_OPTIONS = [
  {
    display: 'Equal to',
    displayValue: '=',
    value: 'eq',
  },
  {
    display: 'Less than',
    displayValue: '<',
    value: 'lt',
  },
  {
    display: 'Less than or equal to',
    displayValue: '<=',
    value: 'lte',
  },
  {
    display: 'Greater than',
    displayValue: '>',
    value: 'gt',
  },
  {
    display: 'Greater than or equal to',
    displayValue: '>=',
    value: 'gte',
  },
];

export const BOOLEAN_OPTIONS = [
  {
    display: 'Yes',
    value: '1',
  },
  {
    display: 'No',
    value: '0',
  },
];

export const YES_NO_OPTIONS = [
  {
    display: 'yes',
    value: '1',
  },
  {
    display: 'no',
    value: '0',
  },
];

export const EMPTY_PROJECT_STEP: ProjectStep = {
  name: 'Step',
  previousStepRequired: false,
  tasks: [],
  status: 'inactive',
  activationTracker: null,
  completionTracker: null,
};

export const EMPTY_PROJECT: Project = {
  label: 'Untitled_1',
  color: '#B8B8B8',
  steps: [{...EMPTY_PROJECT_STEP}],
  startDateTime: null,
  endDateTime: null,
  private: false,
  status: 'active',
  program: [],
};

export const PROJECT_WIZARD_DIALOG_CONFIG: MatDialogConfig = {
  backdropClass: 'oph-backdrop',
  panelClass: 'schedule-project-dialog',
  width: '720px',
  maxWidth: '720px',
  height: '520px',
  maxHeight: '520px',
  disableClose: true,
};

export const PROJECTS_ADD_SKED_DIALOG_CONFIG: MatDialogConfig = {
  backdropClass: 'oph-backdrop',
  panelClass: 'schedule-project-dialog',
  width: '100%',
  maxWidth: '530px',
  minHeight: '540px',
  maxHeight: '90%',
};

export const PROJECT_ACTION_OPTIONS: ProjectActionOption[] = [
  {
    action: 'edit',
    icon: 'pencil-outline',
  },
  {
    action: 'duplicate',
    icon: 'plus',
  },
  {
    action: 'delete',
    icon: 'x-circle',
  },
];

export const EMPTY_ROUTINE_DETAIL_PARAMS: ProjectViewRoutineDetailParams = {
  status: 'all',
  page: 1,
  pageSize: 15,
};

export const EMPTY_TOKEN_FILTER_PARAMS: ProjectViewTokenFilterParams = {
  time: '2',
  type: '',
};
