import {Injectable, Pipe, PipeTransform} from '@angular/core';
import {ProjectTask} from 'src/app/core/model/task';

@Injectable({
  providedIn: 'root',
})
@Pipe({
  name: 'doesTaskHaveTrigger',
  standalone: true,
})
export class DoesTaskHaveTriggerPipe implements PipeTransform {
  transform(task: ProjectTask): boolean {
    return !!task.triggers.some(trigger => trigger.trackerId && trigger.metricId);
  }
}
