import {Action as ActionModel} from '@ngrx/store';
import {Program} from 'src/app/programs/shared/model/program';

export enum ProgramsActionType {
  GET = '[Programs] Get',
  GET_SUCCESS = '[Programs] Get :: Success',

  CREATE = '[Programs] Create',
  CREATE_SUCCESS = '[Programs] Create :: Success',

  UPDATE = '[Programs] Update',
  UPDATE_SUCCESS = '[Programs] Update :: Success',

  DELETE = '[Programs] Delete',
  DELETE_SUCCESS = '[Programs] Delete :: Success',

  CLEAR = '[Programs] Clear',
}

export class GetProgramsAction implements ActionModel {
  public readonly type = ProgramsActionType.GET;

  public constructor(
    public payload: {
      onSuccess?: (programs: string[]) => void;
      onFailure?: (error: Error) => void;
    }
  ) {}
}

export class GetProgramsSuccessAction implements ActionModel {
  public readonly type = ProgramsActionType.GET_SUCCESS;

  public constructor(public payload: {programs: Program[]}) {}
}

export class CreateProgramAction implements ActionModel {
  public readonly type = ProgramsActionType.CREATE;

  public constructor(
    public payload: {
      program: Program;
      onSuccess?: (program: Program) => void;
      onFailure?: (error: Error) => void;
    }
  ) {}
}

export class CreateProgramSuccessAction implements ActionModel {
  public readonly type = ProgramsActionType.CREATE_SUCCESS;

  public constructor(public payload: {program: Program}) {}
}

export class UpdateProgramAction implements ActionModel {
  public readonly type = ProgramsActionType.UPDATE;

  public constructor(
    public payload: {
      program: Program;
      onSuccess?: (program: Program) => void;
      onFailure?: (error: Error) => void;
    }
  ) {}
}

export class UpdateProgramSuccessAction implements ActionModel {
  public readonly type = ProgramsActionType.UPDATE_SUCCESS;

  public constructor(public payload: {program: Program}) {}
}

export class DeleteProgramAction implements ActionModel {
  public readonly type = ProgramsActionType.DELETE;

  public constructor(
    public payload: {
      id: string;
      onSuccess?: () => void;
      onFailure?: (error: Error) => void;
    }
  ) {}
}

export class DeleteProgramSuccessAction implements ActionModel {
  public readonly type = ProgramsActionType.DELETE_SUCCESS;
}

export class ClearProgramsAction implements ActionModel {
  public readonly type = ProgramsActionType.CLEAR;
}

export type ProgramsAction =
  | GetProgramsAction
  | GetProgramsSuccessAction
  | CreateProgramAction
  | CreateProgramSuccessAction
  | UpdateProgramAction
  | UpdateProgramSuccessAction
  | DeleteProgramAction
  | DeleteProgramSuccessAction
  | ClearProgramsAction;
