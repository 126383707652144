<!-- ###############################################################################################

Commented code will get added back later

############################################################################################### -->

@if (
  {
    selectedSkedType: selectedSkedType$ | async,
    teamsMap: teamsMap$ | async,
    activeUser: activeUser$ | async,
    usersMap: usersMap$ | async,
    userTeamList: userTeamList$ | async
  };
  as state
) {
  @if (headerLoading$ | async) {
    <div class="oph-orange-spinner">
      <mat-spinner [diameter]="23" [strokeWidth]="2"></mat-spinner>
    </div>
  }
  <button
    class="sked-selector-button"
    [matMenuTriggerFor]="skedMenu"
    (menuOpened)="onMenuOpened(state.selectedSkedType)"
  >
    <!-- @if (state.selectedSkedType.type === 'user' && state.activeUser?._id === state.selectedSkedType?.id) {
      <span class="button-text">My Skeds</span>
    } -->
    <!-- @if (state.selectedSkedType.type === 'user' && state.activeUser?._id !== state.selectedSkedType?.id) {
      <mat-icon class="user-icon" svgIcon="user"></mat-icon>
      <span class="button-text">{{
        state.usersMap?.[state.selectedSkedType?.id]?.username || '(User name not found)'
      }}</span>
    } -->
    <!-- @if (state.selectedSkedType.type === 'team') {
      <mat-icon class="user-icon" svgIcon="users"></mat-icon>
      <span class="button-text">{{ state.teamsMap?.[state.selectedSkedType?.id]?.name || 'My Team' }}</span>
    } -->
    @if (state.selectedSkedType.type === 'user') {
      <span class="button-text">My Sked</span>
    }
    @if (state.selectedSkedType.type === 'team') {
      <span class="button-text">My Team</span>
    }
    @if (state.selectedSkedType.type === 'all') {
      <span class="button-text">All Day</span>
    }
    <oph-icon name="chevron-down-orange" size="24"></oph-icon>
  </button>

  <mat-menu #skedMenu="matMenu" class="oph-select-menu">
    <button
      class="menu-button"
      class="oph-select-menu-item"
      (click)="onSelect({type: 'user', id: state.activeUser?._id})"
    >
      My Sked
    </button>
    @if (state.activeUser?.teams?.[0]) {
      <button
        class="menu-button"
        class="oph-select-menu-item"
        (click)="onSelect({type: 'team', id: state.activeUser?.teams?.[0]})"
      >
        My Team
      </button>
    }
    <!-- @if (state.activeUser.teams?.length) {
      <div class="oph-schedule-menu-title">My Teams</div>
      @for (team of state.activeUser.teams; track $index) {
        <button class="oph-select-menu-item" (click)="onSelect({type: 'team', id: team}, false)" [id]="team.id">
          {{ state.teamsMap?.[team]?.name || '(Team name not found)' }}
        </button>
      }
    } -->
    <button class="menu-button" class="oph-select-menu-item" (click)="onSelect({type: 'all', id: null}, false)">
      All Day
    </button>

    <!-- @if ((isAdmin$ | async) === true) {
      <div class="admin-title">ADMIN</div>
      <div class="oph-schedule-menu-title">Users</div>
      @for (user of state.userTeamList?.users; track $index) {
        <button
          (click)="onSelect({type: 'user', id: user.id}, true)"
          class="oph-select-menu-item w-full"
          [id]="user.id"
        >
          <span>{{ user.label }}</span>
        </button>
      }
      @if (state.userTeamList?.teams.length) {
        <div class="oph-schedule-menu-title">Teams</div>
        @for (team of state.userTeamList?.teams; track $index) {
          <button
            (click)="onSelect({type: 'team', id: team.id}, true)"
            class="oph-select-menu-item w-full"
            [id]="team.id"
          >
            <span>{{ team.label }}</span>
          </button>
        }
      }
    } -->
  </mat-menu>
}
