import {AsyncPipe} from '@angular/common';
import {HttpErrorResponse} from '@angular/common/http';
import {ChangeDetectionStrategy, Component, HostBinding, OnDestroy, OnInit} from '@angular/core';
import {MatIconModule} from '@angular/material/icon';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {Router} from '@angular/router';
import {Store} from '@ngrx/store';
import * as moment from 'moment-timezone';
import {BehaviorSubject, filter, Observable, Subscription, take, withLatestFrom} from 'rxjs';
import {Project} from '../core/model/project';
import {ReportingTrackers, ReportingTrackersParams} from '../core/model/reporting';
import {Tracker} from '../core/model/tracker';
import {GetProjectsAction} from '../core/store/projects/projects.action';
import {selectAllProjects, selectProjectsMap} from '../core/store/projects/projects.selector';
import {GetTrackersReportAction, UpdateReportingTrackersParamsAction} from '../core/store/reporting/reporting.action';
import {selectReportingTrackers, selectReportingTrackersParams} from '../core/store/reporting/reporting.selector';
import {CreateNewTrackerAction, UpdateTempTrackerProjectIdAction} from '../core/store/trackers/trackers.action';
import {ProjectSelectorMenuProjectButtonComponent} from '../projects/project-selector/project-button/project-selector-menu-project-button.component';
import {ProjectSelectorComponent} from '../projects/project-selector/project-selector.component';
import {MessageService} from '../services/message.service';
import {TimezoneService} from '../services/timezone.service';
import {OphChipModule} from '../shared/design/oph-chip/oph-chip.module';
import {OphLoadingModule} from '../shared/design/oph-loading/oph-loading.module';
import {OphNewButtonComponent} from '../shared/design/oph-new-button/oph-new-button.component';
import {IsEvenNumberPipe} from '../shared/pipes/is-even-number.pipe';
import {TrackerMetricValuePipe} from '../shared/pipes/tracker-metric-value.pipe';
import {getInitialReportingTrackerParams} from './shared/get-initial-reporting-tracker-params';

@Component({
  selector: 'reporting',
  standalone: true,
  imports: [
    AsyncPipe,
    ProjectSelectorComponent,
    ProjectSelectorMenuProjectButtonComponent,
    OphNewButtonComponent,
    OphChipModule,
    IsEvenNumberPipe,
    TrackerMetricValuePipe,
    MatIconModule,
    OphLoadingModule,
    MatProgressSpinnerModule,
  ],
  templateUrl: './reporting.component.html',
  styleUrl: './reporting.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ReportingComponent implements OnInit, OnDestroy {
  @HostBinding('class') hostClasses = 'oph-feature-layout';

  subscriptions = new Subscription();

  reportingTrackers$: Observable<ReportingTrackers> = this.store$.select(selectReportingTrackers);
  reportingTrackersParams$: Observable<ReportingTrackersParams> = this.store$.select(selectReportingTrackersParams);
  projects$: Observable<Project[]> = this.store$.select(selectAllProjects);
  projectsMap$: Observable<Record<string, Project>> = this.store$.select(selectProjectsMap);

  addTrackerButtonLoading$ = new BehaviorSubject<boolean>(false);
  nextLoading$ = new BehaviorSubject<boolean>(false);
  lastLoading$ = new BehaviorSubject<boolean>(false);
  isCurrentWeek$ = new BehaviorSubject<boolean>(false);

  weekArray: {display: string; value: string}[];

  constructor(
    private store$: Store,
    private timezoneService: TimezoneService,
    private router: Router,
    private messageService: MessageService
  ) {}

  ngOnInit(): void {
    this.store$.dispatch(new GetProjectsAction({}));
    this.projectsMap$
      .pipe(
        filter(p => Object.keys(p).length > 0),
        take(1)
      )
      .subscribe(() => this.subscriptions.add(this.subscribeToParams()));
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  subscribeToParams(): Subscription {
    return this.reportingTrackersParams$.pipe(withLatestFrom(this.projectsMap$)).subscribe(([params, projectsMap]) => {
      if (!params) {
        // If there are no params, set them with the default ones
        const initialParams = getInitialReportingTrackerParams(this.timezoneService.getTimeZone(), projectsMap);
        this.store$.dispatch(new UpdateReportingTrackersParamsAction({reportingTrackersParams: initialParams}));
        return;
      }
      this.isCurrentWeek$.next(
        moment(params.startDate)
          .tz(this.timezoneService.getTimeZone())
          .isSame(moment().tz(this.timezoneService.getTimeZone()), 'week')
      );
      this.weekArray = this.getWeekArray(params.startDate);
      this.store$.dispatch(
        new GetTrackersReportAction({
          params,
          onSuccess: () => this.onGetTrackerReportSuccess(),
          onFailure: err => this.onGetTrackerReportFailure(err),
        })
      );
    });
  }

  onGetTrackerReportSuccess() {
    this.clearLoading();
  }

  onGetTrackerReportFailure(err: HttpErrorResponse) {
    this.clearLoading();
    this.messageService.add(err.error || 'There was a problem getting the report');
  }

  clearLoading() {
    this.nextLoading$.next(false);
    this.lastLoading$.next(false);
  }

  getWeekArray(startDate: string): {display: string; value: string}[] {
    const weekArray = [];
    const start = moment(startDate).tz(this.timezoneService.getTimeZone());

    for (let i = 0; i < 7; i++) {
      const currentDate = start.clone().add(i, 'days');
      weekArray.push({
        display: currentDate.format('ddd, MMM D'),
        value: currentDate.format('MM-DD-YYYY'),
      });
    }
    return weekArray;
  }

  onSelectProject(project: Project) {
    this.reportingTrackersParams$.pipe(take(1)).subscribe(params => {
      const newParams = {...params, projectId: project._id};
      this.store$.dispatch(new UpdateReportingTrackersParamsAction({reportingTrackersParams: newParams}));
    });
  }

  onAddTracker(projectId: string) {
    this.addTrackerButtonLoading$.next(true);
    this.store$.dispatch(
      new CreateNewTrackerAction({
        onSuccess: tracker => this.onCreateTrackerSuccess(tracker, projectId),
        onFailure: err => this.onCreateTrackerFailure(err),
      })
    );
  }

  onCreateTrackerSuccess(tracker: Tracker, projectId: string) {
    if (tracker?._id) {
      this.store$.dispatch(new UpdateTempTrackerProjectIdAction({projectId}));
      this.router.navigate(['trackers', tracker._id, {returnTo: this.router.url}]);
    }
    this.addTrackerButtonLoading$.next(false);
  }

  onCreateTrackerFailure(err: Error) {
    this.addTrackerButtonLoading$.next(false);
    this.messageService.add(err.message || 'There was a problem creating the tracker');
  }

  onWeek(next: boolean) {
    this.reportingTrackersParams$.pipe(take(1)).subscribe(params => {
      let startDate = moment(params.startDate).tz(this.timezoneService.getTimeZone());
      let endDate = moment(params.endDate).tz(this.timezoneService.getTimeZone());
      if (!next) {
        // Adjust to previous week
        this.lastLoading$.next(true);
        startDate = startDate.subtract(1, 'weeks');
        endDate = endDate.subtract(1, 'weeks');
      } else {
        // Adjust to next week
        this.nextLoading$.next(true);
        startDate = startDate.add(1, 'weeks');
        endDate = endDate.add(1, 'weeks');
      }

      const reportingTrackersParams = {...params, page: 1, startDate: startDate.format(), endDate: endDate.format()};
      this.store$.dispatch(new UpdateReportingTrackersParamsAction({reportingTrackersParams}));
    });
  }
}
