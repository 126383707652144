import {CdkDragDrop, CdkDropList, CdkDropListGroup, moveItemInArray, transferArrayItem} from '@angular/cdk/drag-drop';
import {AsyncPipe} from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  inject,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import {FormControl} from '@angular/forms';
import {MatDialog} from '@angular/material/dialog';
import {MatIconModule} from '@angular/material/icon';
import {MatTooltipModule} from '@angular/material/tooltip';
import {select, Store} from '@ngrx/store';
import * as _ from 'lodash';
import {BehaviorSubject, debounceTime, filter, map, Observable, Subscription} from 'rxjs';
import {Project, ProjectStep} from 'src/app/core/model/project';
import {RewardCoin} from 'src/app/core/model/reward';
import {SkillModel} from 'src/app/core/model/skill';
import {ProjectTask, TaskTemplate} from 'src/app/core/model/task';
import {Tracker, TrackerMetric} from 'src/app/core/model/tracker';
import {selectLocationsMap} from 'src/app/core/store/locations/locations.selector';
import {
  AddProjectTaskAction,
  GetTaskTemplatesAction,
  MoveProjectTaskAction,
  PutProjectAction,
} from 'src/app/core/store/projects/projects.action';
import {selectSkillsList, selectSkillsMap} from 'src/app/core/store/skills/skills.selector';
import {selectTrackers, selectTrackersMap} from 'src/app/core/store/trackers/trackers.selector';
import {Location} from 'src/app/locations/shared/model/location';
import {MessageService} from 'src/app/services/message.service';
import {OphAddButtonComponent} from 'src/app/shared/design/oph-add-button/oph-add-button.component';
import {
  OphButtonGroupComponent,
  OphButtonGroupOption,
} from 'src/app/shared/design/oph-button-group/oph-button-group.component';
import {OphIconModule} from 'src/app/shared/design/oph-icon/oph-icon.module';
import {OphSearchInputComponent} from 'src/app/shared/design/oph-inputs/oph-search-input/oph-search-input.component';
import {OphNewButtonComponent} from 'src/app/shared/design/oph-new-button/oph-new-button.component';
import {TASK_DIALOG_SETTINGS} from 'src/app/shared/tasks/task-dialog/shared/task-dialog-constants';
import {TaskDialogComponent} from 'src/app/shared/tasks/task-dialog/task-dialog.component';
import {TaskWizardDialogComponent} from 'src/app/shared/tasks/task-wizard-dialog/task-wizard-dialog.component';
import {formatSelectedDay} from 'src/app/shared/tasks/utils/format-selected-day';
import {ProjectEditStepDialogComponent} from './step-dialog/project-edit-step-dialog.component';
import {ProjectsEditTaskComponent} from './task/projects-edit-task.component';
import {PipesModule} from 'src/app/shared/pipes/pipes.module';
import {IsTrackerCompletePipe} from './shared/is-tracker-complete.pipe';
import {OphChipButtonComponent} from 'src/app/shared/design/oph-chip-button/oph-chip-button.component';

interface TaskDropData {
  tasks?: ProjectTask[];
  taskTemplates?: ProjectTask[];
  isTemplate: boolean;
  stepIndex?: number;
  isStep?: boolean;
}

interface MetricDisplay {
  activation: {name: string; value: string};
  completion: {name: string; value: string};
}

@Component({
  selector: 'project-edit',
  standalone: true,
  imports: [
    ProjectsEditTaskComponent,
    AsyncPipe,
    OphButtonGroupComponent,
    OphSearchInputComponent,
    OphNewButtonComponent,
    OphIconModule,
    CdkDropListGroup,
    CdkDropList,
    OphAddButtonComponent,
    MatTooltipModule,
    MatIconModule,
    PipesModule,
    IsTrackerCompletePipe,
    OphChipButtonComponent,
  ],
  templateUrl: './project-edit.component.html',
  styleUrl: './project-edit.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProjectEditComponent implements OnInit, OnChanges, OnDestroy {
  @Input() project: Project;
  @Input() taskTemplates: TaskTemplate[];
  @Input() coinTypes: RewardCoin[];

  requirements$: Observable<SkillModel[]> = this.store$.pipe(select(selectSkillsList));
  requirementsMap$: Observable<Record<string, SkillModel>> = this.store$.pipe(select(selectSkillsMap));
  locationsMap$: Observable<Record<string, Location>> = this.store$.pipe(select(selectLocationsMap));
  trackers$: Observable<Tracker[]> = this.store$.pipe(select(selectTrackers));
  trackersMap$: Observable<Record<string, Tracker>> = this.store$.pipe(select(selectTrackersMap));
  metricsDisplay$: Observable<MetricDisplay[]>;

  selectedTab$ = new BehaviorSubject<string>('task templates');
  loadingStepIndex$ = new BehaviorSubject<number>(null);
  loadingTaskIndex$ = new BehaviorSubject<number>(null);

  readonly dialog = inject(MatDialog);
  tabOptions: OphButtonGroupOption[] = [{name: 'task templates'}, {name: 'milestones', disabled: true}];
  search: FormControl = new FormControl('');
  searchSub: Subscription;

  constructor(
    private store$: Store,
    private messageService: MessageService
  ) {}

  ngOnInit() {
    this.searchSub = this.search.valueChanges.pipe(debounceTime(300)).subscribe(search => {
      this.store$.dispatch(new GetTaskTemplatesAction({search}));
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.project && this.project) {
      this.metricsDisplay$ = this.getMetricsDisplay();
    }
  }
  1;
  ngOnDestroy() {
    this.searchSub.unsubscribe();
  }

  getMetricsDisplay(): Observable<MetricDisplay[]> {
    return this.trackersMap$.pipe(
      filter(t => !!t),
      map(trackersMap =>
        this.project.steps.map(step => {
          const activationTracker = trackersMap[step.activationTracker?.trackerId];
          const activationMetric = activationTracker?.metrics?.find(m => m._id === step.activationTracker?.metricId);
          const completionTracker = trackersMap[step.completionTracker?.trackerId];
          const completionMetric = completionTracker?.metrics?.find(m => m._id === step.completionTracker?.metricId);
          return {
            activation: {
              name: activationMetric?.name || '',
              value: activationMetric ? this.formatMetricValue(activationMetric, step.activationTracker.value) : '',
            },
            completion: {
              name: completionMetric?.name || '',
              value: completionMetric ? this.formatMetricValue(completionMetric, step.completionTracker.value) : '',
            },
          };
        })
      )
    );
  }

  formatMetricValue(metric: TrackerMetric, value: string): string {
    if (metric.type === 'boolean') {
      return value === '1' ? 'Yes' : 'No';
    }

    return value;
  }

  taskDrop(event: CdkDragDrop<TaskDropData>) {
    // If task is dropped in origin location
    if (
      event.previousIndex === event.currentIndex &&
      event.container.data.stepIndex === event.previousContainer.data.stepIndex
    ) {
      return;
    }

    if (event.previousContainer.data.isTemplate) {
      this.handleTemplateDrop(event);
      return;
    }

    if (event.previousContainer === event.container) {
      this.handleSameContainerDrop(event);
      return;
    }

    if (event.previousContainer !== event.container) {
      this.handleDifferentContainerDrop(event);
    }
  }

  handleTemplateDrop(event: CdkDragDrop<TaskDropData>) {
    const task = _.cloneDeep(event.previousContainer.data.taskTemplates[event.previousIndex]);
    if (task?.schedule) {
      // Change default dates to today
      const today = formatSelectedDay(new Date());
      task.schedule.dueWithinDate = today;
      task.schedule.endsDate = today;
    }
    task.stepId = this.project.steps[event.container.data.stepIndex]?._id;
    event.container.data.tasks.splice(event.currentIndex, 0, task);
    this.loadingTaskIndex$.next(event.currentIndex);
    this.loadingStepIndex$.next(event.container.data.stepIndex);
    this.addProjectTask(task, event.currentIndex);
  }

  addProjectTask(task: ProjectTask, currentIndex: number) {
    this.store$.dispatch(
      new AddProjectTaskAction({
        projectId: this.project._id,
        stepId: task.stepId,
        task,
        taskIndex: currentIndex,
        onSuccess: () => this.onUpdateSuccess(),
        onFailure: err => this.onUpdateFailure(err),
      })
    );
  }

  handleSameContainerDrop(event: CdkDragDrop<TaskDropData>) {
    moveItemInArray(event.container.data.tasks, event.previousIndex, event.currentIndex);
    this.loadingTaskIndex$.next(event.currentIndex);
    this.loadingStepIndex$.next(event.container.data.stepIndex);
    this.updateProjectAction(this.project);
  }

  updateProjectAction(project: Project) {
    this.store$.dispatch(
      new PutProjectAction({
        project,
        onSuccess: () => this.onUpdateSuccess(),
        onFailure: err => this.onUpdateFailure(err),
      })
    );
  }

  handleDifferentContainerDrop(event: CdkDragDrop<TaskDropData>) {
    const task = _.cloneDeep(event.previousContainer.data.tasks[event.previousIndex]);
    transferArrayItem(
      event.previousContainer.data.tasks,
      event.container.data.tasks,
      event.previousIndex,
      event.currentIndex
    );
    this.loadingTaskIndex$.next(event.currentIndex);
    this.loadingStepIndex$.next(event.container.data.stepIndex);

    // When a task is moved to a different step, the task is first deleted then, on success, added to the new step with a the new step id
    const newStepId = this.project.steps[event.container.data.stepIndex]?._id;
    if (!newStepId) {
      this.messageService.add('Cannot find step id');
      return;
    }
    task.stepId = newStepId;

    this.store$.dispatch(
      new MoveProjectTaskAction({
        projectId: this.project._id,
        stepId: task.stepId,
        task,
        taskIndex: event.currentIndex,
        onSuccess: () => this.onUpdateSuccess(),
        onFailure: err => this.onUpdateFailure(err),
      })
    );
  }

  onUpdateSuccess() {
    this.loadingTaskIndex$.next(null);
    this.loadingStepIndex$.next(null);
  }

  onUpdateFailure(err: Error) {
    this.loadingTaskIndex$.next(null);
    this.loadingStepIndex$.next(null);
    this.messageService.add(err.message || 'There was a problem updating the project.');
  }

  onCreateTaskForProject(stepIndex: number) {
    const stepId = this.project.steps[stepIndex]?._id;
    const taskIndex = this.project.steps[stepIndex].tasks.length;
    if (!stepId || (!taskIndex && taskIndex !== 0)) {
      this.messageService.add('Cannot find step id or task index');
      return;
    }

    const dialogRef = this.dialog.open(TaskWizardDialogComponent, {
      data: {
        project: this.project,
        coinTypes: this.coinTypes,
        stepId,
        taskIndex,
      },
      panelClass: 'task-wizard-dialog',
      disableClose: true,
    });
    dialogRef.afterClosed().subscribe((manualEntry: boolean) => {
      if (manualEntry) {
        this.openManualProjectTaskModal(stepIndex, stepId, taskIndex, true);
      }
    });
  }

  openManualProjectTaskModal(stepIndex: number, stepId: string, taskIndex: number, newTask?: boolean) {
    // This is to prevent clicking on a task that is loading
    if (
      (stepIndex || stepIndex === 0) &&
      stepIndex === this.loadingStepIndex$.value &&
      (taskIndex || taskIndex === 0) &&
      taskIndex === this.loadingTaskIndex$.value
    ) {
      return;
    }
    this.dialog.open(TaskDialogComponent, {
      data: {
        task: this.project.steps[stepIndex].tasks[taskIndex] || null,
        project: this.project,
        coinTypes: this.coinTypes,
        stepId,
        taskIndex,
        newTask,
      },
      panelClass: 'task-dialog',
      disableClose: true,
    });
  }

  onTabChange(tab: string) {
    this.selectedTab$.next(tab);
  }

  openTaskTemplateDialog(task?: TaskTemplate) {
    this.dialog.open(TaskDialogComponent, {
      data: {task, template: true, project: this.project},
      ...TASK_DIALOG_SETTINGS,
    });
  }

  alwaysAllowDrag(): boolean {
    return true; // This always allows the drag action to start
  }

  onOpenStepDialog(step: ProjectStep, stepIndex: number): void {
    this.dialog.open(ProjectEditStepDialogComponent, {
      data: {step, stepIndex, project: this.project},
      width: '600px',
      height: '610px',
    });
  }

  onStepEdit(step: ProjectStep, stepIndex: number) {
    this.onOpenStepDialog(step, stepIndex);
  }
}
