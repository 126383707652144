import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {Store} from '@ngrx/store';
import {BehaviorSubject} from 'rxjs';
import {Role} from '../core/model/role';
import {User} from '../core/model/user';
import {GetActiveUserAction, SetActiveUserRole} from '../core/store/active-user/active-user.action';
import {GetAllRolesAction} from '../core/store/roles/roles.action';
import {GetAllUsersAction} from '../core/store/users/users.action';
import {AppThemeService} from './../shared/services/app-theme.service';
import {MessageService} from './message.service';
import {UpdateSelectedSkedTypeAction} from '../core/store/current-sked/current-sked.action';

type ResolveType<T> = (value: T) => void;

@Injectable({
  providedIn: 'root',
})
export class AppInitService {
  usersLoaded$ = new BehaviorSubject<boolean>(false);
  rolesLoaded$ = new BehaviorSubject<boolean>(false);

  constructor(
    private store$: Store,
    private messageService: MessageService,
    private router: Router,
    private appThemeService: AppThemeService
  ) {}

  initApp(pathname: string) {
    this.appThemeService.load();
    return new Promise(resolve => {
      if (pathname?.startsWith('/reset-password/')) {
        return resolve(true);
      }

      const currentUser = JSON.parse(localStorage.getItem('currentUser') || null);
      if (!currentUser?.email) {
        this.router.navigate(['login']);
        return resolve(true);
      }

      this.store$.dispatch(
        new GetActiveUserAction({
          email: encodeURIComponent(currentUser.email),
          onSuccess: user => this.onGetActiveUserSuccess(user, resolve),
          onFailure: () => {
            this.onGetActiveUserFailure(), resolve(true);
          },
        })
      );
    });
  }

  onGetActiveUserSuccess(activeUser: User, resolve: ResolveType<boolean>) {
    if (!activeUser) {
      this.messageService.add('User session expired.');
      this.router.navigate(['login']);
      return resolve(true);
    }

    this.store$.dispatch(new UpdateSelectedSkedTypeAction({selectedSkedType: {type: 'user', id: activeUser._id}}));
    this.store$.dispatch(new GetAllUsersAction({onSuccess: () => this.onGetUsersSuccess(resolve)}));
    this.store$.dispatch(
      new GetAllRolesAction({onSuccess: roles => this.onGetRolesSuccess(roles, activeUser, resolve)})
    );
  }

  onGetUsersSuccess(resolve: ResolveType<boolean>) {
    this.usersLoaded$.next(true);
    this.checkIfRolesAndUsersLoaded(resolve);
  }

  onGetActiveUserFailure() {
    this.router.navigate(['login']);
    // this.messageService.add('There was a problem getting the active user.');
  }

  onGetRolesSuccess(roles: Role[], activeUser: User, resolve: ResolveType<boolean>) {
    const role = roles?.find(r => activeUser?.roles[0] === r.id)?.displayName;
    if (role) {
      this.store$.dispatch(new SetActiveUserRole({role}));
    }
    this.rolesLoaded$.next(true);
    this.checkIfRolesAndUsersLoaded(resolve);
  }

  checkIfRolesAndUsersLoaded(resolve: ResolveType<boolean>) {
    if (this.usersLoaded$.value && this.rolesLoaded$.value) {
      resolve(true);
    }
  }
}
