import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {ReactiveFormsModule} from '@angular/forms';
import {MatButtonModule} from '@angular/material/button';
import {MatButtonToggleModule} from '@angular/material/button-toggle';
import {MatIconModule} from '@angular/material/icon';
import {MatListModule} from '@angular/material/list';
import {MatMenuModule} from '@angular/material/menu';
import {RouterModule} from '@angular/router';
import {SharedDubaiThemeModule} from 'src/app/current-sked/shared/dubai-theme/shared-dubai-theme.module';
import {ScheduleSharedModule} from 'src/app/schedule/shared/schedule-shared.module';
import {SharedCurrentSkedModule} from '../../current-sked/shared-current-sked.module';
import {DesignModule} from '../../design/design.module';
import {OphAvatarComponent} from '../../design/oph-avatar/oph-avatar.component';
import {OphSearchInputComponent} from '../../design/oph-inputs/oph-search-input/oph-search-input.component';
import {SharedProjectsModule} from '../../projects/shared-projects.module';
import {NavbarItemComponent} from './navbar-item/navbar-item.component';
import {OphTopBarComponent} from './oph-top-bar/oph-top-bar.component';
import {PageTitleComponent} from './page-title/page-title.component';
import {UserMenuComponent} from './user-menu/user-menu.component';

@NgModule({
  imports: [
    CommonModule,
    DesignModule,
    RouterModule,
    MatListModule,
    MatIconModule,
    MatMenuModule,
    MatButtonModule,
    SharedCurrentSkedModule,
    ScheduleSharedModule,
    SharedDubaiThemeModule,
    SharedProjectsModule,
    MatButtonToggleModule,
    ReactiveFormsModule,
    OphAvatarComponent,
    OphSearchInputComponent,
  ],
  declarations: [UserMenuComponent, PageTitleComponent, NavbarItemComponent, OphTopBarComponent, OphTopBarComponent],
  exports: [DesignModule, UserMenuComponent, RouterModule, PageTitleComponent, NavbarItemComponent, OphTopBarComponent],
})
export class SharedLayoutModule {}
